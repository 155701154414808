@mixin breakpoint($point) {
	@if $point==sm {
		@media (min-width: 576px) {
			@content;
		}
	} @else if $point==max-sm {
		@media (max-width: 575px) {
			@content;
		}
	} @else if $point==md {
		@media (min-width: 768px) {
			@content;
		}
	} @else if $point==max-md {
		@media (max-width: 767px) {
			@content;
		}
	} @else if $point==lg {
		@media (min-width: 992px) {
			@content;
		}
	} @else if $point==max-lg {
		@media (max-width: 991px) {
			@content;
		}
	} @else if $point==xl {
		@media (min-width: 1200px) {
			@content;
		}
	} @else if $point==max-xl {
		@media (max-width: 1199px) {
			@content;
		}
	} @else if $point==xxl {
		@media (min-width: 1400px) {
			@content;
		}
	} @else if $point==max-xxl {
		@media (max-width: 1399px) {
			@content;
		}
	}
}

@font-face {
	font-family: "Clash Display";
	font-style: normal;
	font-weight: 700;
	font-stretch: 100%;
	font-display: swap;
	src: url("./assets/fonts/ClashDisplay-Bold.otf") format("opentype");
}
@font-face {
	font-family: "Clash Display";
	font-style: normal;
	font-weight: 400;
	font-stretch: 100%;
	font-display: swap;
	src: url("./assets/fonts/ClashDisplay-Regular.otf") format("opentype");
}
@font-face {
	font-family: "Clash Display";
	font-style: normal;
	font-weight: 300;
	font-stretch: 100%;
	font-display: swap;
	src: url("./assets/fonts/ClashDisplay-Light.otf") format("opentype");
}
@font-face {
	font-family: "Clash Display";
	font-style: normal;
	font-weight: 500;
	font-stretch: 100%;
	font-display: swap;
	src: url("./assets/fonts/ClashDisplay-Medium.otf") format("opentype");
}
@font-face {
	font-family: "Clash Display";
	font-style: normal;
	font-weight: 600;
	font-stretch: 100%;
	font-display: swap;
	src: url("./assets/fonts/ClashDisplay-Semibold.otf") format("opentype");
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");

:root {
	--title: #ffffff;
	--text: #e9e9e9;
	--text-2: #e9e9e9;
	--body: #0e1111;
	--section: #0E0E11;
	--section-2: #1e1e27;
	--section-3: #14141A;
	--section-4: #1b1b23;
	--section-5: #0E0E11;
	--white: #ffffff;
	--base: #e2295b;
	--base-2: #57bcaf;
	--body-fonts: "Open Sans", sans-serif;
	--heading-fonts: "Clash Display";
	--poppins: "Poppins", sans-serif;
	--border: #212128;
}

body {
	background: var(--body);
	font-family: var(--body-fonts);
	color: var(--text-2);
	font-weight: normal;
}
h1,
h2,
h3,
h4,
h5,
h6 {
	color: var(--title);
	font-family: var(--heading-fonts);
	font-weight: 700;
}

h1 {
	font-size: 54px;
}
h2 {
	font-size: 45px;
}
h3 {
	font-size: 37px;
}
h4 {
	font-size: 31px;
}
h5 {
	font-size: 26px;
}
h6 {
	font-size: 22px;
}
@include breakpoint(max-md) {
	h1 {
		font-size: 34px;
	}
	h2 {
		font-size: 28px;
	}
	h3 {
		font-size: 24px;
	}
	h4 {
		font-size: 22px;
	}
	h5 {
		font-size: 18px;
	}
	h6 {
		font-size: 16px;
	}
}
@include breakpoint(max-sm) {
	h1 {
		font-size: 28px;
	}
	h2 {
		font-size: 24px;
	}
	h3 {
		font-size: 22px;
	}
	h4 {
		font-size: 20px;
	}
	h5 {
		font-size: 18px;
	}
	h6 {
		font-size: 16px;
	}
}
ul {
	padding: 0;
	margin: 0;
	li {
		list-style: none;
	}
}
* {
	padding: 0;
	margin: 0;
	box-sizing: border-box;
}
a {
	text-decoration: none;
	transition: all ease 0.3s;
}
.font-light {
	font-weight: 300;
}
.font-regular {
	font-weight: 400;
}
.font-medium {
	font-weight: 500;
}
.font-semibold {
	font-weight: 600 !important;
}
.font-bold {
	font-weight: 700;
}
.font-extra-bold {
	font-weight: 800;
}
.font-black {
	font-weight: 900;
}
.container {
	position: relative;
	z-index: 1;
}
@include breakpoint(xxl) {
	.container {
		max-width: 1350px;
		padding-left: 15px;
		padding-right: 15px;
	}
}
.bg-section {
	background: var(--section) !important;
}
.bg-body {
	background: var(--body) !important;
}
body {
	.container-fluid {
		padding-inline: 20px;
		@include breakpoint(xxl) {
			padding-inline: 28px;
		}
	}
}
.header-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	column-gap: 20px;
	padding-block: 10px;
	.logo {
		display: block;
		max-width: 140px;
		img {
			width: 100%;
		}
	}
	.search-form {
		flex-grow: 1;
		max-width: 968px;
		width: 300px;
		@include breakpoint(xl) {
			max-width: 56.1vw;
		}
		@media screen and (min-width: 1820px) {
			max-width: 59.5vw;
		}
	}
	@include breakpoint(lg) {
		column-gap: 50px;
	}
	@include breakpoint(xl) {
		column-gap: 100px;
	}
	@media screen and (max-width: 424px) {
		column-gap: 15px;
		.logo {
			max-width: 100px;
		}
		.right-icons {
			column-gap: 15px;
		}
	}
}
.group-input {
	position: relative;
	.search-btn {
		position: absolute;
		left: 0;
		top: 0;
		width: 50px;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.form-control {
		padding-left: 58px;
		height: 48px;
		background: var(--section-4);
		color: var(--white);
		border-radius: 7px;
		border: none;
		outline: none;
		box-shadow: none;
		&::placeholder {
			color: #88869f;
		}
		@include breakpoint(max-sm) {
			height: 42px;
		}
	}
}
.no-gutter {
	border: none;
	outline: none;
	box-shadow: none;
	background: transparent;
}
.header-section {
	position: sticky;
	top: 0;
	z-index: 99;
	&.active {
		background: var(--section);
	}
}
.right-icons {
	display: flex;
	align-items: center;
	column-gap: 32px;
	.no-gutter {
		background: transparent !important;
		padding: 0;
		&::after {
			display: none;
		}
	}
	.avatar {
		width: 37px;
		aspect-ratio: 1;
		object-fit: cover;
		border-radius: 50%;
		border: 2px solid var(--white);
	}
	@include breakpoint(max-lg) {
		column-gap: 20px;
	}
}
.profile-notify {
	width: 410px;
	max-width: 100vw;
	padding: 13px 25px;
	top: calc(100%) !important;
	border-radius: 1rem;
	@include breakpoint(md) {
		right: -20px !important;
	}
	&::after {
		content: "";
		background: var(--section-4);
		position: absolute;
		right: 37px;
		bottom: 100%;
		width: 12px;
		height: 15px;
		clip-path: polygon(100% 0, 0% 100%, 100% 100%);
	}
}
.dropdown-menu {
	background: var(--section-4);
	color: var(--white);
	font-size: 14px;
}
.notify-item {
	padding: 10px 0;
	display: flex;
	color: var(--white);
	&:hover {
		color: var(--white);
	}
	.notify-thumb {
		width: 60px;
		aspect-ratio: 1;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			border-radius: 8px;
		}
	}
	.notify-content {
		padding-left: 16px;
		width: 0;
		flex-grow: 1;
	}
	.notify-title {
		font-size: 15px;
		font-style: normal;
		font-weight: 600;
		font-family: var(--poppins);
		margin: 0;
	}
	.notify-text {
		font-weight: 400;
		line-height: 1.5;
		opacity: 0.5;
	}
}
.w-0 {
	width: 0;
}
.cursor-pointer {
	cursor: pointer;
}
.main-section {
	display: flex;
	min-height: calc(100vh - 75px);
	align-items: flex-start;
	.close-menu-icon {
		position: fixed;
		left: 255px;
		top: 80px;
		z-index: 9999;
		transition: all ease 0.3s;
		&.active {
			left: 80px;
		}
		@include breakpoint(max-xxl) {
			@include breakpoint(lg) {
				left: 280px;
			}
		}
		@include breakpoint(max-lg) {
			left: -80px;
			&.active {
				left: 320px;
			}
		}
	}
	.sidebar {
		background: var(--section);
		max-width: 271px;
		width: 100%;
		// border-radius: 37px;
		display: flex;
		flex-direction: column;
		position: sticky;
		top: 0;
		height: 100vh;
		transition: all ease 0.3s;
		border-radius: 0 0 0px 0;
		border-right: 1px solid rgba(255, 255, 255, .12);
		.sidebar-menu {
			padding: 0 24px 24px 24px;

			li {
				button,
				a {
					font-family: 'Clash Display';
					display: flex;
					align-items: center;
					gap: 16px;
					padding: 21px 19px;
					font-size: 18px; // 20px
					color: #a6a5ac;
					background: transparent;
					border: none;
					outline: none;
					box-shadow: none;
					width: 100%;
					.icon {
						svg {
							height: 20px;
							width: 20px;
						}
					}
					&::before {
						content: "";
						left: 27px;
						bottom: 0;
						width: calc(100% - 54px);
						background: var(--border);
						height: 1px;
						position: absolute;
					}
					&::after {
						content: "";
						left: 27px;
						bottom: 0;
						position: absolute;
						transition: all ease 0.4s;
						border-radius: 17px;
						height: calc(100% - 26px);
						left: -27px;
						bottom: 13px;
						background: var(--base);
						opacity: 0;
						width: 10px;
					}
					&.active {
						font-weight: 600;
						border-radius: 17px;
						color: var(--white);
						background: var(--section-2);
						&::before {
							opacity: 0;
						}
						&::after {
							opacity: 1;
						}
					}
					position: relative;
				}
				.submenu {
					max-width: 180px;
					width: 100%;
					margin: 0 auto;
					padding-block: 14px;
					position: relative;
					padding-left: 18px;
					&::before {
						content: "";
						border-left: 1px solid var(--border);
						height: calc(100% - 32px);
						left: 0;
						top: 0;
						position: absolute;
					}
					li {
						position: relative;
						&::before {
							content: "";
							left: -18px;
							bottom: 17px;
							background: var(--border);
							height: 1px;
							width: 18px;
							position: absolute;
						}
						a {
							font-size: 15px;
							font-style: normal;
							font-weight: 400;
							padding-block: 8px;
							border-radius: 8px;
							justify-content: flex-start;
							text-align: left;
							&::after,
							&::before {
								display: none;
							}
						}
					}
				}
				&:last-child {
					a {
						&::before {
							opacity: 0;
						}
					}
				}
			}
		}
		.sidebar-bottom {
			border-radius: 37px 37px 0px 0px;
			background: #1B1B26;
			display: flex;
			flex-direction: column;
			.sidebar-bottom-top {
				padding: 39px 20px 20px;
				border-bottom: 2px solid var(--border);
				.media {
					display: flex;
					align-items: center;
					gap: 13px;
					position: relative;
					.avatar {
						position: relative;
						border-radius: 50%;
						border: 1px dashed var(--base);
						width: 70px;
						aspect-ratio: 1;
						padding: 4px;
						.img {
							width: 100%;
							border-radius: 50%;
						}
						.diamond {
							position: absolute;
							right: 0;
							bottom: 0;
						}
					}
					small {
						font-size: 12px;
						line-height: 113%;
						color: #88869f;
					}
					h5 {
						margin: 0;

						font-size: 18px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						letter-spacing: 1px;
					}
				}
			}
			.sidebar-bottom-bottom {
				display: flex;
				justify-content: space-evenly;
				padding-block: 10px 20px;
				button,
				a {
					width: 25px;
					max-width: 47px;
					flex-grow: 1;
					padding-block: 14px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
			}
			.sidebar-menu-2 {
				padding: 25px 0;
				border-top: 2px solid var(--border);
				margin-top: 39px;
				display: flex;
				gap: 23px;
				flex-direction: column;
				li {
					a {
						color: #88869f;

						font-style: normal;
						font-weight: 400;
						line-height: normal;
						display: flex;
						align-items: center;
						gap: 16px;
						font-size: 15px;
						@include breakpoint(xl) {
							font-size: 18px;
						}
					}
				}
			}
			.progress-info {
				padding: 14px 0 6px;
			}
			.progress-label {
				color: #88869f;
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 113%;
			}
			strong.progress-label {
				font-weight: 600;
			}
			position: sticky;
			bottom: 0;
			@include breakpoint(max-lg) {
			}
			&.active {
				position: absolute;
				bottom: 0;
				width: 100%;
				z-index: 1;
				.sidebar-bottom-top {
					order: 1;
					border-bottom: none;
					border-top: 2px solid var(--border);
					padding-top: 24px;
				}
				.cmn-btn:not(:hover) {
					background: #282834;
					border: 2px solid #282834;
					color: #88869f;
				}
				.sidebar-bottom-bottom {
					padding-block: 8px;
				}
			}
		}
		.close-menu {
			display: none;
		}
		.logo {
			display: block;
			max-width: 180px;
			margin: 23px 0 0 40px;
			img {
				max-width: 100%;
			}
		}
		overflow-y: auto;
		overflow-x: visible;
		// thin scrollbar
		&::-webkit-scrollbar {
			width: 5px;
		}
		&::-webkit-scrollbar-track {
			background: transparent;
		}
		&::-webkit-scrollbar-thumb {
			background: var(--border);
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: var(--border);
		}
		.sidebar-communities {
			padding-bottom: 29px;
			margin: 29px;
			padding-top: 40px;
			border-bottom: 2px solid var(--border);
			.subtitle {
				font-size: 20px;
				font-family: 'Clash Display';
				font-weight: 500;
				margin-bottom: 11px;
			}
			img {
				max-width: 100%;
				border-radius: 6px;
			}
		}
		@include breakpoint(max-lg) {
			position: fixed;
			left: 0;
			top: 0;
			max-height: 100vh;
			overflow-y: auto;
			z-index: 999;
			border-radius: 0 37px 37px 0;
			transition: all ease 0.3s;
			.close-menu {
				display: block;
				position: absolute;
				right: 10px;
				top: 10px;
				z-index: 99;
			}
			&:not(.active) {
				transform: translateX(-100%);
				visibility: hidden;
				opacity: 0;
			}
		}
		@include breakpoint(lg) {
			&.active {
				width: 99px;
				.logo {
					margin-left: 0;
					text-align: center;
				}
				.sidebar-menu {
					padding: 10px;
					li {
						.sparkles,
						.name {
							display: none;
						}
						button {
							justify-content: center;
						}
						a {
							padding-block: 20px;

							justify-content: center;
							&.active {
								background: transparent;
							}
							&::before {
								display: none;
							}
							&::after {
								left: -8px;
							}
						}
						.submenu {
							display: none;
						}
					}
				}
				.sidebar-bottom {
					background: transparent;
				}
				.sidebar-communities {
					.subtitle {
						display: none;
					}
				}
				.sidebar-communities-subtitle-2 {
					display: none;
				}
				.sidebar-communities-2 {
					padding-right: 0;
					a {
						padding-inline: 0;
						justify-content: center;
						.cont {
							display: none;
						}
					}
				}
			}
			&.chatbar-close {
				width: 0;
				overflow: hidden;
				transition: all ease 0.3s;
			}
		}
		// Sizing
		@include breakpoint(max-xxl) {
			@include breakpoint(lg) {
				max-width: 300px;
			}
			.sidebar-menu {
				padding: 44px 27px 34px;
				li {
					a {
						padding: 18px 24px;
						font-size: 18px;
						gap: 12px;
						&::after {
							width: 7px;
						}
					}
				}
			}
		}
	}
	.articles {
		width: 0;
		flex-grow: 1;
		padding-bottom: 25px;
		transition: all ease 0.3s;
		background: #0E0E11;
		@media screen and (min-width: 1650px) {
			.container-fluid {
				padding-right: 57px;
			}
		}
	}
}
.__progress {
	border-radius: 5px;
	background: #1e1e28;
	height: 8px;
	.progress-bar {
		border-radius: 5px;
		background: linear-gradient(90deg, var(--base) 0%, var(--base-2) 83.33%);
	}
}
.text-base {
	color: var(--base) !important;
}
.cmn-btn {
	background: var(--base);
	padding: 13px 42px;
	border: 2px solid var(--base);
	outline: none;
	box-shadow: none;
	font-weight: 700;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: 113%;
	color: var(--white);
	border-radius: 11px;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 6px;
	transition: all ease 0.3s;
	&:hover {
		background: transparent;
		color: var(--base);
	}
	&.btn-black {
		background: transparent;
		color: var(--white);
		border-color: var(--white);
		&:hover {
			background: var(--base);
			border-color: var(--base);
			color: var(--white);
		}
	}
}
hr {
	border-color: var(--border);
	opacity: 1;
}
.banner-section {
	border-radius: 37px;
	margin-left: 40px;
	margin-right: 40px;
}
.banner-item {
	border-radius: 17px;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 139px 58px 139px 66px;
	gap: 30px;
	.banner-content {
		width: 0;
		max-width: 709px;
		justify-content: space-between;
		flex-grow: 1;
		.subtext {
			font-size: 18px;
			font-family: var(--body-fonts);
			text-transform: uppercase;
			margin-bottom: 13px;
		}
		.banner-title {
			font-size: 66px;
			font-style: normal;
			font-weight: 600;
			margin-bottom: 12px;
		}
		.banner-text {
			margin-bottom: 57px;
		}
		font-size: 17px;
		font-style: normal;
		font-weight: 400;
		line-height: 30px;
		.cmn-btn {
			font-size: 15px;
			font-style: normal;
			font-weight: 700;
			padding: 11px 22px;
		}
	}
	@media screen and (max-width: 1600px) {
		padding: 139px 58px;
		.banner-content {
			.banner-title {
				font-size: 50px;
			}
		}
	}
	@include breakpoint(max-xxl) {
		padding: 100px 50px;
	}
	@include breakpoint(max-xxl) {
		padding: 50px 50px 100px;
		flex-wrap: wrap;
		row-gap: 50px;
		.banner-content {
			width: 100%;
			.banner-title {
				font-size: 50px;
				line-height: 1;
			}
			.banner-text {
				margin-bottom: 36px;
			}
		}
	}
	.banner-right {
		width: 100%;
		max-width: 263px;
		.banner-right-card {
			background: #292933;
			border-radius: 10px;
			.top {
				padding: 7px 19px;
				font-size: 16px;
				font-style: normal;
				font-weight: 500;
				line-height: 113%;
				letter-spacing: 0.8px;
				font-family: var(--heading-fonts);
			}
			.banner-right-body {
				border-radius: 10px;
				background: var(--section-3);
				background: linear-gradient(239deg, #24242c 1.49%, #121217 97.97%);
				padding: 18px 17px 22px;
				.txt1 {
					color: #ffffff;
					text-overflow: ellipsis;
					font-size: 12px;
					font-style: normal;
					// font-weight: 400;
					line-height: 144%;

					margin-bottom: 17px;
				}
				.text-15 {
					font-size: 15px;
				}
				.txt2 {
					font-size: 12px;
					font-style: normal;
					// font-weight: 400;
					line-height: 144%;
					color: #ffffff;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					margin-bottom: 17px;
				}
			}
		}
	}
	@include breakpoint(max-md) {
		padding: 50px 30px 100px;
		.banner-content {
			.banner-title {
				font-size: 40px;
			}
		}
	}
	@include breakpoint(max-sm) {
		padding: 50px 15px 80px;
		.banner-content {
			.subtext {
				font-size: 15px;
				margin-bottom: 16px;
			}
			.banner-title {
				font-size: 30px;
			}
		}
	}
}
.note-info-items {
	h6 {
		font-size: 10px;
		font-weight: 600;
		line-height: 113%; /* 11.3px */
		letter-spacing: 0.5px;
	}
}
.swiper-pagination {
	bottom: 24px !important;
	.swiper-pagination-bullet {
		width: 72px;
		height: 6px;
		background: #ffffff;
		opacity: 0.29;
		border-radius: 3px;
		cursor: pointer;
		&.swiper-pagination-bullet-active {
			opacity: 1;
		}
		@include breakpoint(max-lg) {
			width: 36px;
			height: 4px;
		}
	}
}
.sidebar-widget-title {
	font-weight: 600;
}
.article-area {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	padding-inline: 68px 38px;
	padding-bottom: 38px;
	gap: 30px;
	@media screen and (min-width: 1550px) {
		gap: 30px;
	}
	.post-area {
		width: 0;
		flex-grow: 1;
	}
	.sidebar-area {
		width: 100%;
		@include breakpoint(xl) {
			max-width: 345px;
			@media screen and (max-width: 1550px) {
				max-width: 300px;
			}
		}
		display: flex;
		flex-direction: column;
		gap: 60px;
		padding: 28px 22px;
		border: 1px solid rgba(#ffffff, 0.2);
		border-radius: 23px;
		.sidebar-widget {
			.sidebar-widget-title {
				margin-bottom: 22px;
				font-weight: 600;
				font-size: 18px;
				display: flex;
				align-items: center;
				gap: 4px;
			}
			.sidebar-widget-body {
				background: var(--section-3);
				border-radius: 10px;
				padding: 16px 17px 12px;
				.txt1 {
					color: rgba(233, 233, 233, 0.5);
					display: -webkit-box;
					-webkit-line-clamp: 3;
					-webkit-box-orient: vertical;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 144%;
					max-width: 254px;
				}
				.signal-item {
					small {
						color: #88869f;
						text-align: center;
						font-size: 10px;
						font-style: normal;
						font-weight: 400;
						line-height: 113%;
					}
				}
			}
		}
	}
	@include breakpoint(max-xl) {
		gap: 30px;
		padding-inline: 30px;
		.post-area {
			width: 100%;
		}
	}
	@include breakpoint(max-sm) {
		padding-inline: 15px;
	}
}
.eth-btn {
	h6 {
		margin: 0;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 113%; /* 18.08px */
		letter-spacing: 0.8px;
	}
}
.text-16 {
	font-size: 16px;
}
.__media {
	gap: 10px;
	display: flex;
	align-items: center;
	img {
		width: 36px;
		aspect-ratio: 1;
		object-fit: cover;
		border-radius: 50%;
	}
	.media-body {
		small {
			font-size: 12px;
			color: #88869f;
			line-height: 113%;
			display: block;
		}
	}
}
.x-post {
	.txt2 {
		display: -webkit-box;
		-webkit-line-clamp: 5;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #e9e9e9;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 144%;
	}
}
.post-icons {
	button {
		color: #88869f;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 18px;
		display: flex;
		align-items: center;
		gap: 5px;
		img,
		svg {
			width: 18px;
			height: unset;
		}
		span {
			display: block;
			line-height: 12px;
		}
		&.text-16 {
			font-size: 16px;
		}
		&.upvote {
			svg {
				width: 66px;
			}
		}
	}
}
.community-sidebar-item {
	display: flex;
	align-items: center;
	max-width: 100%;
	gap: 9px;
	background: var(--section-3);
	border-radius: 11px;
	padding: 12px 14px;
	color: #88869f;
	.community-thumb {
		width: 36px;
		border-radius: 6px;
		overflow: hidden;
		img {
			width: 100%;
		}
	}
	.community-title {
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 113%;
		letter-spacing: 0.7px;
		color: var(--white);
		margin: 0;
		@include breakpoint(max-sm) {
			font-size: 15px;
			white-space: initial;
			word-break: break-all;
		}
	}
	.community-content {
		flex-grow: 1;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 1.1;
		color: #88869f;
		display: block;
		.community-view {
			display: block;
		}
		svg {
			width: 16px;
		}
	}
	&.community-sidebar-item-2 {
		.community-thumb {
			width: 66px;
			border-radius: 9px;
		}
	}
}
.form-select {
	cursor: pointer;
	background-color: var(--section-3);
	color: var(--white);
	border: none;
	outline: none;
	box-shadow: none !important;
	background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%2388869F%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e");
	background-size: 16px;
	&:focus {
		color: var(--white);
		background-color: var(--section-3);
	}
	padding-inline: 19px 40px;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 113%;
	letter-spacing: 0.75px;
	height: 44px;
	@include breakpoint(max-sm) {
		padding-inline: 12px 30px;
		font-size: 12px;
	}
}
.textarea-wrapper {
	background: var(--section-3);
	border-radius: 10px;

	.form-control {
		height: 62px;
		background: transparent;
		border: none;
		outline: none;
		box-shadow: none;
		color: var(--white);
		padding: 15px 28px;
		resize: none;
		&::placeholder {
			color: #88869f !important;
		}
	}
	.buttons-area {
		padding: 13px 16px;
		.cmn-btn {
			@include breakpoint(max-sm) {
				font-size: 14px;
				padding-inline: 24px;
			}
		}
	}
}
.text-btn {
	&::after {
		content: "";
		position: absolute;
		left: 50%;
		transform: translateX(-50%);
		width: 70%;
		height: 6px;
		background: var(--base);
		border-radius: 5px;
		opacity: 0;
		bottom: -6px;
	}
	position: relative;
	padding: 12px;
	color: var(--white);
	&:hover {
		color: var(--base);
	}
	&.active {
		&::after {
			opacity: 1;
		}
	}
	font-family: var(--heading-fonts);
	font-weight: 600;
	font-style: normal;
	line-height: 113%;
	letter-spacing: 0.9px;
	@include breakpoint(sm) {
		font-size: 18px;
		&::after {
			height: 10px;
			bottom: -12px;
		}
	}
}
.text-12 {
	font-size: 12px;
}
.text-text {
	color: #88869f;
}
.post-item {
	.normal-text {
		@include breakpoint(sm) {
			font-size: 18px;
		}
	}
}
.rounded-md {
	border-radius: 14px;
}
.load-more {
	background: #282834;
	border: 2px solid #282834;
	border-radius: 10px;
}
.bg-base {
	background: var(--base);
}
.pinned-card {
	border-radius: 10px;
	.pinned-card-body {
		border-radius: 10px;
		padding: 26px 20px;
		background: var(--section-3);
	}
	.load-more {
		color: #88869f;
		padding: 4px 14px;
	}
}
.pinned-post-header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 12px 28px;
	.text-base [fill="white"] {
		fill: var(--base);
	}
}
.add-story {
	border: none;
	outline: none;
	box-shadow: none;
	background: transparent;
	text-align: center;
	.plus-icon {
		position: absolute;
		bottom: 0;
		right: 0;
	}
	img {
		border-radius: 50%;
		width: 46px;
		border: 2px solid rgba(#fff, 0.3);
	}
	width: 48px;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 113%;
	letter-spacing: 0.5px;
	color: var(--white);
	.name {
		font-family: var(--heading-fonts);
		margin-top: 5px;
	}
	&:hover {
		color: var(--base);
	}
}
.swiper-slide {
	&:nth-child(1) {
		.add-story {
			img {
				border-color: var(--base);
			}
		}
	}
	&:nth-child(2) {
		.add-story {
			img {
				border-color: var(--base-2);
			}
		}
	}
}
.story-divider {
	width: 2px;
	height: 42px;
	background: var(--border);
}
.border-bottom-2 {
	border-bottom: 2px solid var(--border);
}
.story-slider {
	.swiper-slide {
		width: 46px;
	}
	display: flex;
	gap: 10px;
	.prev-icon,
	.next-icon {
		transition: all ease 0.3s;
		&.disabled {
			opacity: 0;
		}
	}
}
.signal-widget {
	.dolar-box {
		margin-bottom: -45px;
		position: relative;
		z-index: 1;
	}
	&-wrapper {
		padding: 50px 18px 22px;
		border-bottom: 2px solid var(--border);
		border-radius: 20px;
		border: 1px solid #34343e;
		background: linear-gradient(
			211deg,
			rgba(52, 52, 62, 0.5) 1.99%,
			rgba(14, 14, 17, 0.5) 98.94%
		);
		backdrop-filter: blur(10px);
		.maintxt,
		.subtxt {
			font-family: var(--poppins);
		}
		.subtxt {
			font-size: 10px;
		}
		.time-stamp {
			font-size: 12px;
		}
		.info-texts {
			.s-title {
				font-size: 14px;
				font-style: normal;
				font-weight: 600;
				margin: 0;
			}
			small {
				font-size: 10px;
				font-style: normal;
				font-weight: 400;
				color: #c7c7c7;
			}
			.btn {
				height: 20px;
				font-size: 10px;
				padding: 0;
				min-width: 45px;
			}
		}
	}
}
.usd-long {
	border-radius: 5px;
	background: #282834;
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	padding: 16px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	align-items: center;
	.name {
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		margin-bottom: 0;
	}
	.long-badge {
		color: #37ff7b;
		border-radius: 5px;
		background: #344339;
		padding: 8px;
	}
}
.profile-sidebar {
	width: 100%;
	font-size: 12px;
	padding-inline: 20px;
	@include breakpoint(lg) {
		max-width: 390px;
	}
	@include breakpoint(xxl) {
		max-width: 400px;
		padding-inline: 60px;
	}
	.profile-img {
		border-radius: 50%;
		aspect-ratio: 1;
		object-fit: cover;
		margin-bottom: 16px;
	}
	.name {
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 1px;
		@include breakpoint(max-sm) {
			font-size: 18px;
		}
	}
	.pinned-card {
		.pinned-card-body {
			.normal-text {
				font-size: 11px;
				font-style: normal;
				font-weight: 400;
				line-height: 20px;
			}
		}
	}
}
.trader-badge {
	border-radius: 180px;
	background: #282834;
	padding: 7px 15px;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	display: inline-flex;
	align-items: center;
	gap: 8px;
}
.profile-counts {
	margin-top: 30px;
	display: flex;
	gap: 8px;
	.btn {
		flex-grow: 1;
		border-radius: 7px;
		border: 1px solid #323242;
		color: var(--white);
	}
}
.btn--secondary {
	border-radius: 12px;
	background: rgba(136, 134, 159, 0.1);
	border: 1px solid rgba(136, 134, 159, 0.1);
	color: var(--white);
	&:active,
	&:hover,
	&:focus {
		background: rgba(136, 134, 159, 0.1);
		border: 1px solid var(--base);
	}
	&.--badge:active,
	&.--badge:hover,
	&.--badge:focus,
	&.--badge {
		border-radius: 7px;
		border: 1px solid rgba(136, 134, 159, 0.1);
		color: var(--white);
		cursor: text;
	}
}
.mb-30 {
	margin-bottom: 30px;
}
.badge-area {
	display: flex;
	gap: 10px;
	.badge {
		flex-grow: 1;
	}
}
.badge-soft-success {
	border-radius: 7px;
	border: 1px solid #37ff7b;
	background: rgba(55, 255, 123, 0.1);
	padding: 12px 10px;
	display: block;
	font-size: 16px;
	@include breakpoint(max-sm) {
		font-size: 13px;
	}
}
.badge-soft-danger {
	border-radius: 7px;
	border: 1px solid #ff377f;
	background: rgba(255, 55, 127, 0.1);
	padding: 12px 10px;
	display: block;
	font-size: 16px;
	@include breakpoint(max-sm) {
		font-size: 13px;
	}
}
.badge-soft-secondary {
	border-radius: 7px;
	border: 1px solid #88869f;
	background: rgba(136, 134, 159, 0.1);
	padding: 12px 10px;
	display: block;
	font-size: 16px;
	@include breakpoint(max-sm) {
		font-size: 13px;
	}
}
.h-44 {
	height: 44px;
}

.sidebar-session-info {
	li {
		display: flex;
		text-align: left;
		justify-content: space-between;
		padding-block: 15px;
		&:not(:last-child) {
			border-bottom: 1px solid var(--border);
		}
		color: #9494a8;
		.left {
			display: flex;
			gap: 10px;
			align-items: center;
			font-size: 16px;
		}
	}
}

.text-success {
	color: #37ff7b !important;
}
.px-xxl-38 {
	@include breakpoint(xxl) {
		padding-right: 38px !important;
	}
}
.nav-menu {
	display: flex;
	flex-wrap: wrap;
	column-gap: 37px;
	row-gap: 18px;
	li {
		a {
			.active {
				display: none;
			}
			color: #88869f;
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.9px;
			display: flex;
			align-items: center;
			position: relative;
			&::before {
				position: absolute;
				content: "";
				width: calc(100% - 10px);
				top: calc(100% + 22px);
				left: 8px;
				height: 4px;
				background: linear-gradient(to right, #6b37ff, #ff005c);
				border-radius: 2px;
				opacity: 0;
				visibility: hidden;
				@include breakpoint(max-xxl) {
					top: calc(100% + 8px);
				}
			}
			gap: 6px;
			&.active {
				color: #fff;
				font-weight: 600;
				&::before {
					opacity: 1;
					visibility: visible;
				}
				.active {
					display: inline-block;
				}
				.inactive {
					display: none;
				}
			}
		}
	}
	@include breakpoint(max-xxl) {
		column-gap: 20px;
		li {
			a {
				font-size: 16px;
			}
		}
	}
	@include breakpoint(max-sm) {
		li {
			a {
				font-size: 14px;
			}
		}
	}
}
.profile-card {
	border-radius: 48px;
	background: var(--section);
	&-header {
		padding: 12px 37px 12px 50px;
		border-radius: 48px 48px 0px 0px;
		background: #262631;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		gap: 20px;
		justify-content: space-between;
		.btn--secondary {
			color: #88869f;
			height: 55px;
			min-width: 110px;
			display: flex;
			gap: 10px;
			align-items: center;
			justify-content: center;
			@include breakpoint(xxl) {
				min-width: 170px;
			}
			@include breakpoint(max-sm) {
				min-width: 70px;
				height: 50px;
				font-size: 14px;
			}
		}
	}
	&-body {
		padding: 55px 37px;
	}
	@include breakpoint(max-xxl) {
		.profile-card-header {
			padding: 12px 25px;
		}
		.profile-card-body {
			padding: 25px;
		}
	}
	@include breakpoint(max-sm) {
		.profile-card-header {
			padding: 25px 20px;
		}
		.profile-card-body {
			padding: 25px;
		}
	}
}
.banner-video-card {
	padding: 17px;
	border-radius: 14px;
	color: var(--white);
	background: linear-gradient(
		0deg,
		rgba(0, 0, 0, 0.5) 0%,
		rgba(0, 0, 0, 0.5) 100%
	);
	.badge {
		color: #141414;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
	}
	.name {
		margin-top: 40px;
	}
	position: relative;
	.serial {
		color: var(--white);
		font-size: 38px;
		font-style: normal;
		font-weight: 800;
		line-height: normal;
		position: absolute;
		right: 16px;
		top: 4px;
		opacity: 0.3;
	}
	&:hover {
		color: var(--white);
	}
}
.gap-xl-30 {
	@include breakpoint(xl) {
		gap: 30px;
	}
}
.banner-content-2 {
	.banner-title {
		white-space: initial;
		word-break: break-word;
		@media screen and (max-width: 1650px) {
			@include breakpoint(lg) {
				font-size: 40px !important;
			}
			@include breakpoint(max-sm) {
				font-size: 30px;
			}
		}
	}
}
.article-section-title {
	color: var(--white);
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 113%;
	letter-spacing: 1.2px;
}
.view-all {
	color: #88869f;
	text-align: center;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: 113%;
	letter-spacing: 1px;
	&:hover {
		color: var(--base);
	}
}
.recent-trade-card {
	display: flex;
	gap: 25px;
	@include breakpoint(max-xxl) {
		gap: 20px;
	}
	.trade-img {
		width: 278px;
		border-radius: 16px;
		padding: 25px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		.badge {
			color: #e0e0e0;
			font-family: var(--heading-fonts);
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			padding: 8px 20px;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
			position: absolute;
			left: 0;
			top: 25px;
		}
		.trade-title {
			font-size: 26px;
			font-weight: 600;
		}
		padding-top: 60px;
	}
	a {
		color: var(--white);
		&:hover {
			color: var(--base);
		}
	}
	.trade-content {
		width: 0;
		flex-grow: 1;
		display: flex;
		background: var(--section-3);
		padding: 9px 30px 9px 9px;
		border-radius: 20px;
		gap: 28px;
		> div {
			align-self: center;
		}
		img {
			width: 278px;
			border-radius: 16px;
			object-fit: cover;
		}
		.trade-title {
			font-size: 26px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 1.3px;
		}
		.trade-cate {
			color: #e0e0e0;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			margin-bottom: 20px;
			display: block;
		}
		.trade-text {
			color: rgba(233, 233, 233, 0.5);
			margin-bottom: 29px;
		}
		.trade-price {
			color: #e0e0e0;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			font-family: var(--heading-fonts);
		}
		.view {
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.9px;
		}
		a {
			color: var(--white);
			&:hover {
				color: var(--base);
			}
		}
	}
	position: relative;
	.link {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
	@media screen and (max-width: 1680px) {
		.trade-img,
		.trade-content img {
			width: 220px;
		}
	}
	@media screen and (max-width: 1340px) {
		.trade-img,
		.trade-content img {
			width: 200px;
		}
	}
	@media screen and (min-width: 992px) and (max-width: 1199px) {
		flex-wrap: wrap;
		.trade-img {
			padding-top: 100px;
		}
		.trade-img,
		.trade-content {
			width: 100%;
		}
	}
	@media screen and (max-width: 680px) {
		flex-wrap: wrap;
		.trade-img {
			padding-top: 100px;
		}
		.trade-img,
		.trade-content {
			width: 100%;
		}
	}
	@media screen and (max-width: 575px) {
		.trade-content {
			gap: 20px;
			img {
				width: 160px;
			}
			font-size: 14px;
		}
	}
	@media screen and (max-width: 485px) {
		.trade-content {
			flex-wrap: wrap;
			padding-right: 9px;
			img {
				width: 100%;
				max-height: 278px;
			}
			font-size: 14px;
		}
	}
}
.recent-trade {
	margin-right: -37px;
	overflow: hidden;
	@include breakpoint(max-xxl) {
		margin-right: -25px;
	}
	@media screen and (min-width: 992px) and (max-width: 1199px) {
		margin-right: 0;
	}
	@media screen and (max-width: 680px) {
		margin-right: 0;
	}
	.swiper-slide {
		max-width: 1038px;
	}
}
.session-live {
	border-radius: 20px;
	padding: 48px 36px 53px;
	position: relative;
	@include breakpoint(max-sm) {
		padding: 30px 20px 35px;
	}
	.badge.bg-white {
		border-radius: 100px;
		color: var(--body);
		height: 38px;
		text-transform: uppercase;
		align-items: center;
		justify-content: center;
		display: inline-flex;
		gap: 4px;
		min-width: 88px;
		.dot {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			display: inline-block;
			background: var(--base);
		}
	}
	&::before {
		content: "";
		inset: 0;
		position: absolute;
		border-radius: 20px;
		background: rgba(0, 0, 0, 0.3);
		backdrop-filter: blur(8.5px);
	}
	.inner-div {
		display: flex;
		flex-wrap: wrap;
		gap: 17px;
		.w-240px {
			max-width: 507px;
		}
		.title {
			font-size: 25px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: 1.25px;
		}
		@include breakpoint(md) {
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 1px;
		}
	}
}
.w-240px {
	width: 240px;
}
.session-title {
	font-size: 32px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	letter-spacing: 1.6px;
}
.trade-session-wrapper {
	padding: 29px;
	border-radius: 15px;
	background: #262631;
	@include breakpoint(max-md) {
		padding: 20px;
	}
	.top {
		display: flex;
		flex-wrap: wrap;
		gap: 15px;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 22px;
		border-bottom: 1px solid #33333f;
		.title {
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 1.2px;
		}
	}
	.middle-info {
		padding-block: 22px;
		border-bottom: 1px solid #33333f;
		display: flex;
		justify-content: space-between;
		p {
			max-width: 560px;
			margin: 0;
		}
		.subtitle {
			margin-bottom: 17px;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.9px;
		}
		color: #88869f;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		letter-spacing: 0.6px;
		.price {
			color: #fff;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 1.2px;
		}
	}
	.middle-bottom-info {
		padding-top: 17px;
		color: #88869f;
		p {
			color: #88869f;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.6px;
			max-width: 414px;
		}
		.txt-top {
			display: flex;
			justify-content: space-between;
			font-size: 18px;
			font-style: normal;
			font-weight: 800;
			line-height: normal;
			letter-spacing: 0.9px;
			text-transform: uppercase;
		}
		.price {
			color: #fff;
			font-size: 18px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.9px;
		}
	}
	.cart-quantity-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 20px;
		justify-content: space-between;
		padding-top: 15px;
		.subtitle {
			margin-bottom: 0;
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.9px;
			font-family: var(--body-fonts);
		}
	}
	.cmn-btn {
		color: #fff;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 0.9px;
	}
}
.p-10px {
	padding: 10px;
	color: #88869f;
}
.cart-quantity {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 10px;
	background: #34343e;
	width: 145px;
	.quantity-input {
		width: 40px;
		height: 40px;
		border-radius: 10px;
		background: transparent !important;
		border: none;
		outline: none;
		box-shadow: none;
		color: #88869f;

		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: 0.9px;
		text-align: center;
		flex-grow: 1;
		&::placeholder {
			color: var(--white);
		}
		&::-webkit-inner-outer-button,
		&::-webkit-inner-spin-button {
			display: none;
		}
	}
	.btn {
		width: unset;
		border: none;
		background: transparent;
		&:focus,
		&:active,
		&:hover {
			background: transparent;
			border: none;
			box-shadow: none;
		}
	}
}
.grand-total {
	color: #88869f;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	letter-spacing: 0.9px;
}
.h-52 {
	height: 52px;
}
.no--gutter {
	background: transparent !important;
	padding-inline: 0 !important;
	&::after,
	&::before {
		display: none;
	}
}
.reaction-popup {
	display: flex;
	padding-inline: 16px;
	gap: 10px;
}
.reaction {
	.dropdown-menu {
		&::after {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			content: "";
			left: 10px;
			bottom: calc(100% - 3px);
			background: var(--section-4);
			position: absolute;
		}
	}
}
.feed-posts {
	.normal-text {
		max-width: 680px;
	}
}
.counter-area {
	display: flex;
	gap: 42px;
	flex-wrap: wrap;
	justify-content: space-between;
	&.style-2 {
		.counter-item {
			.counter-value {
				font-size: 40px;
				small {
					font-size: 24px;
					line-height: 1.1;
					display: inline-block;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					letter-spacing: 1.2px;
				}
			}
		}
		.swiper-slide:nth-of-type(3n + 1) {
			.counter-item {
				background: rgba(255, 255, 255, 0.1);
			}
		}
		.swiper-slide:nth-of-type(3n + 2) {
			.counter-item {
				background: rgba(55, 255, 123, 0.1);
				.counter-value,
				.counter-label {
					color: #37ff7b;
				}
			}
		}
		.swiper-slide:nth-of-type(3n + 3) {
			.counter-item {
				background: rgba(255, 55, 127, 0.1);
				.counter-value,
				.counter-label {
					color: #ff377f;
				}
			}
		}
	}
	.counter-item {
		border-radius: 22px;
		padding: 13px 20px;
		text-align: center;
		font-family: var(--heading-fonts);
		background: #1b1b23;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		flex-grow: 1;
		.counter-value {
			font-size: 60px;
			line-height: 1;
			font-weight: 600;
			small {
				font-size: 40px;
			}
			&.text-sm {
				font-size: 50px;
			}
		}
		.counter-label {
			font-size: 18px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			text-transform: uppercase;
		}
		&:nth-child(2) {
			background: var(--base);
		}
		&:nth-child(1),
		&:nth-child(4) {
			background: #57bcaf;
		}
		&:nth-child(3) {
			background: var(--base);
		}
	}
	@media screen and (max-width: 1690px) {
		gap: 30px;
		.counter-item {
			.counter-value {
				font-size: 50px;
				small {
					font-size: 30px;
				}
			}
			.counter-label {
				font-size: 16px;
			}
		}
	}
	@media screen and (max-width: 1585px) {
		.counter-item {
			.counter-value {
				font-size: 40px;
				small {
					font-size: 30px;
				}
				&.text-sm {
					font-size: 36px;
				}
			}
		}
	}
	@media screen and (max-width: 1520px) {
		.counter-item {
			.counter-value {
				font-size: 36px;
				small {
					font-size: 28px;
				}
				&.text-sm {
					font-size: 32px;
				}
			}
		}
	}
	@media screen and (max-width: 1460px) {
		gap: 20px;
		.counter-item {
			.counter-value {
				font-size: 32px;
				small {
					font-size: 24px;
				}
				&.text-sm {
					font-size: 28px;
				}
			}
		}
	}
	@media screen and (max-width: 1250px) {
		gap: 15px;
		.counter-item {
			.counter-value {
				font-size: 30px;
				small {
					font-size: 24px;
				}
				&.text-sm {
					font-size: 26px;
				}
			}
		}
	}
	@media screen and (max-width: 1199px) and (min-width: 992px) {
		gap: 20px;
		.counter-item {
			.counter-value {
				font-size: 36px;
				small {
					font-size: 28px;
				}
				&.text-sm {
					font-size: 32px;
				}
			}
		}
	}
	&.style-1 {
		gap: 20px;
		.counter-item {
			&:nth-child(2) {
				color: var(--base);
				background: rgba(#ff377f, 0.1);
			}
			&:nth-child(1),
			&:nth-child(4) {
				color: var(--base-2);
				background: rgba(#57bcaf, 0.1);
			}
			&:nth-child(3) {
				background: rgba(136, 134, 159, 0.1);
			}
		}
	}
}
.trade-card {
	background: var(--section-4);
	border-radius: 22px;
	padding: 20px 29px;
	position: relative;
	.status {
		border-radius: 0px 0px 10px 10px;
		background: #282834;
		position: absolute;
		right: 29px;
		top: 0;
		padding: 23px 28px;
		text-align: center;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		font-family: var(--heading-fonts);
		.amount {
			font-size: 40px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			color: var(--base);
		}
	}
	.exchanges {
		font-family: var(--heading-fonts);
		color: #37ff7b;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	.item-area {
		font-family: var(--heading-fonts);
		display: flex;
		gap: 20px;
		column-gap: 40px;
		margin-top: 16px;
		margin-bottom: 18px;
		.item {
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			color: #88869f;
			.value {
				font-size: 30px;
				font-weight: 600;
				color: var(--white);
			}
		}
	}
	.text-text {
		letter-spacing: 1px;
	}
	@media screen and (max-width: 1600px) {
		.status {
			padding: 23px 20px;
			.amount {
				font-size: 30px;
			}
		}
		.item-area {
			.item {
				font-size: 16px;
				.value {
					font-size: 24px;
				}
			}
		}
		.post-icons {
			* {
				font-size: 14px;
			}
		}
	}
	@media screen and (max-width: 1510px) {
		.status {
			padding: 23px 18px;
			.amount {
				font-size: 24px;
			}
		}
	}
	@media screen and (max-width: 1300px) {
		padding: 20px 15px;
		.status {
			right: 15px;
		}
	}
	@media screen and (max-width: 424px) {
		padding-top: 25px;
		.exchanges {
			padding-block: 20px;
		}
		.status {
			padding-block: 15px;
		}
	}
}
.rounded-22 {
	border-radius: 24px;
}
.font-heading {
	font-family: var(--heading-fonts);
}
.line-arrow {
	display: block;
	background: var(--white);
	width: 2px;
	height: 18px;
	transform: rotate(18deg);
}
.signal--item {
	border-radius: 20px;
	border: 1px solid #88869f;
	background: linear-gradient(
		211deg,
		rgba(136, 134, 159, 0.5) 1.99%,
		rgba(54, 54, 54, 0.5) 98.94%
	);
	backdrop-filter: blur(10px);
	padding: 21px 20px 0;
	.long-badge {
		height: 34px;
		width: 84px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.signal--item-bottom {
		color: var(--white);
		display: flex;
		justify-content: space-between;
		gap: 5px;
		padding-block: 10px;
		border-top: 2px solid var(--white);
		font-size: 12px;
		span {
			color: rgba(#ffffff, 0.5);
		}
	}
	.usd-long {
		border-radius: 5px;
		background: rgba(40, 40, 52, 0.3);
		padding-block: 10px;
	}
	.signal--item-middle {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		gap: 11px;
		padding-block: 15px 6px;
		.btn {
			padding-block: 0;
			font-size: 12px;
		}
		color: #c7c7c7;
		font-size: 14px;
		.item {
			display: flex;
			align-items: center;
			gap: 5px;
			margin-bottom: 7px;
		}
		strong {
			color: var(--white);
		}
	}
	&.redish {
		border-radius: 20px;
		border: 1px solid #95181d;
		background: linear-gradient(
			211deg,
			rgba(255, 55, 127, 0.5) 1.99%,
			rgba(203, 34, 40, 0.5) 98.94%
		);
		backdrop-filter: blur(10px);
	}
}
.mb-31 {
	margin-bottom: 31px;
}
.video-1 {
	padding: 30px;
	border-radius: 22px;
	background: linear-gradient(
		99deg,
		#1b1b23 2.77%,
		rgba(27, 27, 35, 0) 99.21%
	);
	height: 100%;
	min-height: 208px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	.video-1-content {
		.subtitle {
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			margin-bottom: 6px;
		}
		.title {
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			font-family: var(--body-fonts);
			margin: 0;
			a {
				color: var(--white);
			}
		}
	}
	.video-1-content .title a:hover {
		color: var(--base);
	}
	@include breakpoint(max-sm) {
		padding: 20px;
		.video-1-content {
			.title {
				font-size: 20px;
			}
		}
	}
}
.quote {
	padding: 42px 39px 18px;
	border-radius: 22px;
	background: #1b1b23;
	height: 100%;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	position: relative;
	color: #88869f;
	min-height: 208px;
	display: flex;
	flex-direction: column;
	.quote-icon {
		position: absolute;
		top: 12px;
		right: 38px;
	}
	blockquote {
		margin-bottom: 23px;
		color: #ffffff;
	}
	@include breakpoint(max-sm) {
		padding: 20px;
		.avatar-group {
			gap: 5px;
		}
	}
}
.avatar-group {
	display: flex;
	gap: 14px;
	.avatar {
		width: 31px;
		aspect-ratio: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		padding: 2px;
		img {
			aspect-ratio: 1;
			width: 100%;
			object-fit: cover;
			border-radius: 50%;
		}
		border-radius: 50%;
		&:nth-child(1) {
			border: 1px dashed #ffffff;
		}
		&:hover {
			border: 1px dashed #ffffff;
		}
	}
}
.market-insights {
	border-radius: 22px;
	background: linear-gradient(90deg, #e2295b 0%, #5920b6 100%), #ff377f;
	height: 100%;
	min-height: 208px;
	padding: 22px 25px;
	position: relative;
	.shape {
		position: absolute;
		left: 0;
		top: 0;
	}
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	.title {
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		max-width: 158px;
		@media screen and (min-width: 1650px) {
			margin-left: 20px;
		}
	}
	@media screen and (min-width: 1200px) and (max-width: 1650px) {
		padding: 10px 24px;
		.title {
			max-width: 100px;
			font-size: 16px;
		}
	}
	@include breakpoint(max-sm) {
		padding: 20px 15px;
		.title {
			max-width: 90px;
			font-size: 14px;
		}
	}
}
.insights {
	overflow: hidden;
	.item {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		border-radius: 5px;
		background: rgba(217, 217, 217, 0.2);
		padding: 16px;
		.point {
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			font-family: var(--heading-fonts);
		}
	}
	@media screen and (max-width: 1650px) {
		.item {
			padding: 10px;
		}
	}
	@include breakpoint(max-sm) {
		.title {
			font-size: 14px;
		}
		.item {
			.icon {
				svg {
					width: 36px;
					height: 36px;
				}
			}
			.point {
				font-size: 16px;
			}
		}
	}
}
.react-datepicker__current-month {
	text-transform: uppercase;
}
.super-smash {
	padding: 30px;
	border-radius: 22px;
	background: #1b1b23;
	.badge-secondary {
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		color: #b50c49;
		padding: 8px 14px;
		background: #292933;
		border-radius: 39px;
	}
	.name {
		color: #88869f;
	}
	.title {
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		font-family: var(--heading-fonts);
		margin-bottom: 4px;
	}
	.cate {
		color: #9494a8;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		display: flex;
		align-items: center;
		.bullet {
			width: 4px;
			height: 4px;
			border-radius: 50%;
			background: #9494a8;
			display: inline-block;
			margin: 0 5px;
		}
	}
	.prices {
		margin-top: 21px;
		display: flex;
		justify-content: space-between;
		.price {
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			font-family: var(--heading-fonts);
		}
		a {
			font-size: 18px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.9px;
		}
		.time {
			color: #9494a8;
			font-size: 14px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
		}
	}
	.date {
		position: relative;
		text-transform: uppercase;
		border-radius: 0px 0px 10px 10px;
		background: #1e1e27;
		margin-top: -30px;
		font-family: var(--heading-fonts);
		align-self: flex-start;
		.day {
			font-size: 64px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			color: var(--white);
		}
		padding: 17px 13px;
		font-size: 24px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		@media screen and (max-width: 1480px) and (min-width: 1200px) {
			padding: 17px 10px;
			font-size: 20px;
			line-height: 1.2;
			.day {
				line-height: 1.2;
				font-size: 50px;
			}
		}
	}
	.badge.bg-white {
		color: #b50c49;
		padding: 8px 14px;
		border-radius: 39px;
		background: #292933 !important;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;
		display: inline-flex;
		align-items: center;
		gap: 5px;
		.dot {
			width: 11px;
			height: 11px;
			background: #31ba96;
			display: block;
			border-radius: 50%;
		}
	}
	.txt {
		color: #88869f;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: 144%;
		max-width: 292px;
		margin-top: 18px;
		margin-bottom: 0;
	}
	@include breakpoint(max-sm) {
		padding-inline: 15px;
		.title {
			font-size: 20px;
		}
		.prices {
			margin-top: 15px;
			.price {
				font-size: 20px;
			}
			a {
				font-size: 16px;
			}
		}
		.date {
			padding: 15px 10px;
			font-size: 16px;
			.day {
				font-size: 40px;
			}
		}
		.txt {
			font-size: 12px;
			max-width: 100%;
		}
		.prices {
			a {
				font-size: 14px;
			}
		}
	}
}
.min-h-208px {
	min-height: 208px;
}
.line-limit-3 {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.datepicker--wrapper {
	padding: 24px 30px 16px;
	border-radius: 22px;
	background: #1b1b23;
	height: 100%;
	@include breakpoint(max-sm) {
		padding: 20px 15px 10px;
	}
}
.react-datepicker {
	width: 100%;
	border-radius: 22px;
	border: none;
	background: #1b1b23;
	.react-datepicker__navigation {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 2px;
		background: #282834;
		padding-right: 0;
		padding-left: 4px;
		span {
			top: 5px;
		}
	}
	.react-datepicker__navigation--previous {
		left: calc(100% - 70px);
		padding-left: 0;
		padding-right: 4px;
	}
	.react-datepicker__month-container {
		width: 100%;
	}
	.react-datepicker__header {
		color: var(--text) !important;
		background: transparent;
		border: none;
	}
	.react-datepicker__day-name,
	.react-datepicker__day,
	.react-datepicker__time-name {
		color: var(--text);
		&:hover {
			color: var(--dark);
		}
	}
	.react-datepicker__day-names {
		display: flex;
		justify-content: space-between;
		text-align: center;
		text-transform: uppercase;
		.react-datepicker__day-name {
			flex-grow: 1;
		}
	}
	.react-datepicker__week {
		display: flex;
	}
	.react-datepicker__day {
		border-radius: 2px;
		background: #282834;
		aspect-ratio: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		margin: 1px;
		max-height: 60px;
		&:hover {
			color: var(--base) !important;
		}
		&.react-datepicker__day--selected {
			color: var(--white) !important;
			// background: var(--base) !important;
			border: 1px solid var(--white);
		}
	}
	.react-datepicker__current-month {
		text-align: left;
		font-size: 18px;
	}
	.react-datepicker__day-name,
	.react-datepicker__current-month {
		color: var(--white);
	}
	// .react-datepicker__day--keyboard-selected,
	.react-datepicker__day--in-range {
		border: 1px solid var(--white);
		color: var(--body);
	}
	.react-datepicker__day--disabled {
		opacity: 0.4;
		color: var(--text) !important;
	}
	.react-datepicker__day--outside-month {
		background: #1e1e27 !important;
	}
}
.react-datepicker__day--highlighted-custom-1 {
	position: relative;
	&::before {
		content: "";
		height: 6px;
		width: 6px;
		border-radius: 50%;
		background: #37ff7b;
		position: absolute;
		right: 5px;
		top: 5px;
	}
}
.react-datepicker__day--highlighted-custom-2 {
	position: relative;
	&::before {
		content: "";
		height: 6px;
		width: 6px;
		border-radius: 50%;
		background: var(--base);
		position: absolute;
		right: 5px;
		top: 5px;
	}
}
.h-42 {
	height: 42px;
}
.upto {
	font-size: 14px;
	font-family: var(--body-fonts);
	color: var(--white);
}
.counter-area {
	.swiper-slide {
		flex-grow: 1;
	}
}
.trading--signal-wrapper {
	border-radius: 30px;
	background: #1e1e27;
	padding-bottom: 60px;
	.nav--menu {
		border-bottom: 4px solid var(--border);
		padding-bottom: 24px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: center;
		padding: 24px 60px;
	}
	.swiper {
		padding-inline: 60px;
	}
	@include breakpoint(max-xxl) {
		.nav--menu,
		.swiper {
			padding-inline: 24px;
		}
	}
	@include breakpoint(max-sm) {
		padding-bottom: 24px;
		.nav--menu,
		.swiper {
			padding-inline: 16px;
		}
	}
}
a.text-body {
	color: var(--text) !important;
	&:hover {
		color: var(--base) !important;
	}
}
.styled-2-area {
	padding: 40px 40px 24px;
	.tabs-area {
		.tabs-area-item {
			//button {
			//	font-weight: 600;
			//}
		}
	}
	@include breakpoint(max-xxl) {
		padding: 30px 24px 16px;
	}
	@include breakpoint(max-sm) {
		padding: 30px 16px 16px;
	}
	.banner-section {
		border-radius: 0;
		@include breakpoint(xl) {
			.banner-item {
				padding: 42px 40px 52px;
				min-height: 391px;
			}
			.banner-title {
				font-size: 40px;
				font-weight: 600;
			}
			.banner-text {
				max-width: 448px;
				font-size: 15px;
				font-family: "Plus Jakarta Sans";
				font-style: normal;
				font-weight: 300;
				line-height: 27px;
				margin-bottom: 30px;
			}
		}
	}
}
.customer-auth-section {
	min-height: 100vh;
	// display: flex;
	// align-items: center;
	// justify-content: center;
}
.customer-auth-login-wrapper {
	width: 100%;
	background: var(--body);
	min-height: 100vh;
	max-width: 655px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	.logo {
		display: block;
		width: 100%;
		max-width: 207px;
		margin: 0 auto 66px;
		img {
			width: 100%;
		}
	}
	.customer-auth-login-wrapper-inner {
		padding: 36px 20px;
		max-width: 584px;
		margin: 0 auto;
		width: 100%;
	}
}
.customer-auth-wrapper {
	background: var(--body);
	height: 100%;
	min-height: 100vh;
	max-width: 700px;
	width: 100%;
	&-inner {
		padding: 60px 20px;
		max-width: 634px;
		margin: 0 auto;
		width: 100%;
		.logo {
			display: block;
			width: 100%;
			max-width: 145px;
			margin-bottom: 66px;
			img {
				width: 100%;
			}
		}
		.text {
			color: #88869f;
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
		}
		.title {
			font-weight: 600;
			span {
				font-weight: 400;
			}
		}
		// @include breakpoint(xxl) {
		// 	.title {
		// 		font-size: 60px;
		// 	}
		// }
	}
	&.fulwidth {
		@include breakpoint(max-xl) {
			@include breakpoint(lg) {
				max-width: 100%;
			}
		}
	}
}
.form--label {
	color: #88869f;
	font-style: normal;
	font-weight: 600;
	font-size: 16px;
	@include breakpoint(max-sm) {
		font-size: 14px;
	}
}
.form--label--description {
	color: var(--white);
	font-style: normal;
	font-weight: 400;
	font-size: 15px;
	@include breakpoint(max-sm) {
		font-size: 14px;
	}
}
.form--label--disclaimer {
	color: #9494a8;
	font-style: normal;
	font-weight: 400;
	font-size: 13px;
	@include breakpoint(max-sm) {
		font-size: 14px;
	}
}
.form-control-2 {
	border-radius: 11px;
	border: none;
	background-color: #14141a;
	outline: none;
	box-shadow: none;
	color: var(--white);
	&::placeholder {
		color: #88869f;
	}
	&:focus {
		outline: none;
		box-shadow: none;
		color: var(--white);
		background-color: #14141a;
	}
	padding-inline: 24px;
	height: 55px;
	&.password {
		padding-right: 40px;
	}
	@include breakpoint(max-sm) {
		height: 48px;
	}
}

.eye {
	position: absolute;
	right: 0;
	top: 0;
	width: 48px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	z-index: 9;
}
.form--check {
	color: #88869f;
	font-size: 12px;
	font-style: normal;
	font-weight: 600;
	line-height: 12px;
	text-transform: uppercase;
	display: flex;
	align-items: center;
	gap: 6px;
	padding: 0;
	cursor: pointer;
	.form-check-input {
		width: 24px;
		height: 24px;
		background-color: transparent;
		border-color: var(--border);
		box-shadow: none !important;
		margin: 9px;
		top: unset;
	}
	span {
		display: block;
		width: 0;
		flex-grow: 1;
	}
}
.base-2 {
	--base: var(--base-2);
}
button[type="submit"].cmn-btn {
	&:hover {
		background: var(--base);
		color: var(--white);
	}
}
button[type="submit"].cmn-btn.h-69 {
	font-size: 18px;
	font-family: var(--heading-fonts);
}
.h-69 {
	height: 55px;
	@include breakpoint(max-sm) {
		height: 48px;
	}
}
.or {
	display: flex;
	align-items: center;
	gap: 15px;
	&::after,
	&::before {
		content: "";
		position: relative;
		display: inline-block;
		background: #32324a;
		height: 2px;
		flex-grow: 1;
	}
}
.text-lg {
	font-size: 18px;
}
.dropzone {
	cursor: pointer;
}
.image-uploader-1 {
	aside {
		display: flex;
		*,
		img {
			//height: 67px;
			//width: auto;
			//max-width: 300px;
			//aspect-ratio: 40 / 9;
			//object-fit: contain;
			border-radius: 12px;
			object-position: center;
			background-repeat: no-repeat;
			//background-color: #212128;
		}
	}
}
.dropzone {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}
.image-uploader-1 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	cursor: pointer;
	position: relative;
	min-height: 100px;
	img {
		width: 100%;
		height: 100px;
		object-fit: contain;
		border-radius: 8px;
		object-position: left center;
	}
}
.card-2 {
	background-color: var(--body);
	.card-body {
		width: 100%;
		padding: 32px 23px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 113%;
		letter-spacing: 0.75px;
		color: var(--white);
	}
	.card-title {
		color: var(--white);
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 113%;
		letter-spacing: 1.2px;
		font-family: var(--heading-fonts);
		.badge {
			border-radius: 5px;
			background: rgba(255, 255, 255, 0.1);
			padding: 6px 9px 3px;
		}
	}
	.card-text {
		margin-top: 10px;
	}
	border: 2px solid transparent;
	&.active {
		border-color: var(--base-2);
	}
}
.plan-btn {
	border-radius: 5px;
	background: rgba(255, 255, 255, 0.1);
	height: 45px;
	border: none;
	color: #fff;
	outline: none;
	box-shadow: none;
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	padding-inline: 20px;
	@include breakpoint(xl) {
		min-width: 160px;
	}
	&.active {
		background: var(--base-2);
	}
}
.payment-txt {
	width: 0;
	flex-grow: 1;
	color: rgba(#fff, 0.5);
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 113%;
	.subtitle {
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 113%;
		margin-bottom: 13px;
		display: block;
	}
}
.h-45 {
	height: 45px;
}
.text--center {
	.form-control {
		text-align: center;
	}
}
.__h-45 {
	.form-control {
		height: 45px;
		&::placeholder {
			color: var(--white);
		}
	}
	.form--label {
		font-size: 16px;
		font-family: var(--heading-fonts);
		color: var(--white);
	}
}
.__progress {
	&.base {
		.progress-bar {
			background: var(--base);
		}
	}
	&.base-2 {
		.progress-bar {
			background: var(--base-2);
		}
	}
}
.customer-auth-section {
	&.fullwidth {
		.customer-auth-wrapper {
			max-width: 100%;
			&-inner {
				max-width: 1380px;
			}
		}
	}
}
.form-control-2 {
	input {
		height: 26px;
		width: 26px;
		border: none;
		border: none;
		box-shadow: none;
		outline: none;

	}
}
.form--check-img-group {
	display: flex;
	flex-wrap: wrap;
	max-width: 360px;
	row-gap: 15px;
	img {
		width: 35px;
	}
	.form--check {
		width: 180px;
		align-items: center;
		gap: 14px;
		display: flex;
		.form-check-input {
			margin: 0;
		}
		.form-check-label {
			display: flex;
			gap: 11px;
			align-items: center;
		}
	}
}
.add-pair-txt {
	color: rgba(136, 134, 159, 0.5);
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	display: block;
	white-space: nowrap;
	text-transform: capitalize;
}
button.form-control-2 {
	&::after {
		display: none;
	}
}
.__dropdown-country-menu {
	.dropdown-toggle {
		padding: 0;
		img {
			width: 35px;
		}
	}
	.dropdown-menu {
		max-height: 300px;
		overflow-y: auto;
		text-align: center;
		img {
			width: 35px;
		}
		// scrollbar
		&::-webkit-scrollbar {
			width: 5px;
		}
		&::-webkit-scrollbar-track {
			background: #1b1b23;
		}
		&::-webkit-scrollbar-thumb {
			background: #88869f;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: #88869f;
		}
	}
}
.form--label {
	small {
		color: #88869f;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
}
.img-white-container {
	img {
		border-radius: 50%;
		background: var(--white);
	}
}
.customer-auth-right-side {
	width: 40vw;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 60px 0;
	.customer-auth-right-side-inner {
		padding: 0 20px;
		max-width: 660px;
		margin: 0 auto;
		width: 100%;
		@include breakpoint(xl) {
			padding: 0 30px;
		}
	}
	@include breakpoint(xl) {
		min-height: 100vh;
	}
}
.signal-admin-slider {
	.signal-slider-img {
		border-radius: 37px;
		border: 1px solid rgba(#ffffff, 0.051);
		img {
			width: 100%;
		}
	}
	.title {
		color: #fff;
		font-size: 40px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
	@include breakpoint(max-xl) {
		.title {
			font-size: 30px;
		}
	}
	.swiper-pagination-bullets {
		position: relative;
		justify-content: flex-start;
		display: flex;
		margin: 0;
		margin-top: 50px;
		top: unset;
	}
}
.badge-soft-base {
	background: rgba(255, 55, 127, 0.1);
	color: #ff377f;
	padding: 8px 21px;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	margin-top: 46px;
	display: inline-block;
	margin-bottom: 21px;
}
.social-wrapper {
	max-width: 370px;
	margin: 0 auto;
	button {
		width: 0;
		flex-grow: 1;
		img {
			max-width: 100%;
		}
	}
}
.feedback-product-wrap {
	width: 0;
	flex-grow: 1;
	@include breakpoint(max-xxl) {
		width: 100%;
	}
}
.side-title {
	width: 100%;
	max-width: 136px;
	@include breakpoint(max-xxl) {
		max-width: 100%;
	}
}
.bg-292933 {
	background: #292933;
}
.settings-wrapper {
	padding: 24px;
	display: flex;
	gap: 24px;
	@include breakpoint(max-xl) {
		padding: 24px 0;
	}
	@include breakpoint(xl) {
		gap: 30px;
	}
	@include breakpoint(xxl) {
		gap: 110px;
	}
	.settings-sidebar {
		width: 100%;
		max-width: 268px;
		position: sticky;
		top: 60px;
		.settings-sidebar__item {
			&:not(:last-child) {
				margin-bottom: 22px;
			}
			.settings-sidebar__item-title {
				display: flex;
				gap: 10px;
				align-items: flex-end;
				color: #9494a8;
				text-align: center;
				font-size: 10px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				margin-bottom: 20px;
				&::after {
					content: "";
					flex-grow: 1;
					display: inline-block;
					height: 1px;
					background: #9494a8;
				}
			}
			.settings-sidebar__item-submenu {
				font-size: 18px;
				font-style: normal;
				font-weight: 600;
				a {
					color: var(--white);
					display: block;
					padding: 24px 0;
					border-radius: 17px;
					transition: all 0.3s ease;
					&.active {
						background: #1e1e27;
						padding: 24px;
					}
				}
			}
		}
		@include breakpoint(max-xl) {
			position: fixed;
			top: 0;
			left: 0;
			background: var(--section);
			width: 100%;
			max-width: 320px;
			padding: 24px;
			z-index: 9999;
			height: 100vh;
			overflow-y: auto;
			.settings-sidebar__item {
				&:not(:last-child) {
					margin-bottom: 22px;
				}
				.settings-sidebar__item-submenu {
					font-size: 18px;
					font-style: normal;
					font-weight: 600;
					a {
						color: var(--white);
						display: block;
						padding: 20px 0;
						border-radius: 17px;
						transition: all 0.3s ease;
						font-size: 16px;
						&.active {
							background: #1e1e27;
							padding: 20px;
						}
					}
				}
			}
			&:not(.active) {
				opacity: 0;
				visibility: hidden;
			}
		}
	}
	.settings-article {
		width: 0;
		flex-grow: 1;
		max-width: 738px;
		background: var(--section);
		padding: 30px;
		padding-top: 40px;
		// border-radius: 37px;
		border-radius: 37px 37px 0px 0px;
		background: var(--section);
		@include breakpoint(max-xl) {
			max-width: 100%;
		}
		@include breakpoint(max-sm) {
			padding: 24px 16px;
		}
	}
}
.avatar-upload {
	position: relative;
	img {
		width: 65px;
		aspect-ratio: 1;
		border: 2px solid rgba(#ffffff, 0.1);
		border-radius: 50%;
		object-fit: cover;
	}
	label {
		position: absolute;
		left: -8px;
		bottom: 2px;
		cursor: pointer;
	}
}
.setting-title {
	font-size: 24px;
	font-style: normal;
	font-weight: 600;
	line-height: 113%; /* 27.12px */
	letter-spacing: 1.2px;
	@include breakpoint(max-sm) {
		font-size: 20px;
	}
}
.edit-info-item {
	align-items: center;
	display: flex;
	justify-content: space-between;
	.label {
		color: #88869f;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 113%;
		letter-spacing: 0.6px;
		margin-bottom: 8px;
	}
	.name {
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 113%;
		white-space: initial;
		word-break: break-word;
	}
	.btn--secondary {
		height: 45px;
		border: none;
		border-radius: 5px;
		min-width: 83px;
		@include breakpoint(xl) {
			min-width: 113px;
		}
		font-size: 15px;
		font-weight: 700;
	}
	.form-control {
		background: transparent !important;
		border: 1px solid var(--border);
		color: var(--white);
		box-shadow: none;
		outline: none;
		font-size: 14px;
	}
	.cmn-btn {
		border-radius: 5px;
		@include breakpoint(xl) {
			min-width: 165px;
		}
	}
	@include breakpoint(max-sm) {
		.form-control {
			font-size: 12px;
		}
		.name {
			font-size: 14px;
		}
		.btn--secondary {
			font-size: 12px !important;
			height: 40px;
			min-width: 68px;
		}
	}
}
.btn--secondary.btn--danger {
	border-radius: 5px;
	background: rgba(255, 55, 127, 0.1);
	font-size: 15px;
	font-weight: 700;
	color: #ff377f;
	&.--badge:active,
	&.--badge:hover,
	&.--badge:focus,
	&.--badge {
		border-color: rgba(255, 55, 127, 0.1);
		background: rgba(255, 55, 127, 0.1);
		color: #ff377f;
		cursor: text;
	}
}
.btn--secondary.btn--success {
	border-radius: 5px;
	background: rgba(55, 255, 123, 0.12);
	font-size: 15px;
	font-weight: 700;
	color: #37ff7b;
	&:hover() {
		border-color: #37ff7b;
	}
	&.--badge:active,
	&.--badge:hover,
	&.--badge:focus,
	&.--badge {
		border-color: rgba(55, 255, 123, 0.12);
		background: rgba(55, 255, 123, 0.12);
		color: #37ff7b;
		cursor: text;
	}
}
.cmn-btn.btn-outline {
	border-width: 1px;
	padding-inline: 20px;
	background: var(--base);
	color: var(--white);
	font-size: 15px;
	&:not(:hover) {
		background: transparent;
		color: var(--white);
	}
}
.max-sm-grow {
	@include breakpoint(max-sm) {
		flex-grow: 1;
		width: 135px;
		.btn {
			width: 100%;
			font-size: 12px !important;
		}
	}
}
.notify-group {
	.subtitle {
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 113%;
		letter-spacing: 0.8px;
		margin-bottom: 20px;
	}
	.notify-group-inner {
		border-radius: 12px;
		background: #1e1e27;
		padding: 23px 22px;
		color: #88869f;
	}
}
.notify-group-inner {
	.form--label {
		color: #88869f;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 113%;
	}
	.form-control-2 {
		border-radius: 6px;
		background-color: #2a2a37;
		height: 40px;
		color: #88869f;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 113%;

	}
}
.text-009FD9 {
	color: #009fd9;
}
.border {
	border-color: #88869f !important;
}
.form-check-2 {
	.form-check-input {
		width: 20px;
		height: 20px;
		border-color: #88869f;
		&:checked {
			border-color: #12b38c;
			background-color: #12b38c;
		}
	}
	.form-check-label {
		color: #88869f;
		font-size: 16px;
		font-style: normal;
		font-weight: 600;
		line-height: 113%;
		text-transform: initial;
	}
}
.form-switch {
	.form-check-input {
		cursor: pointer;
		width: 48px;
		height: 24px;
		box-shadow: none !important;
	}
	.form-check-input:focus {
		border-color: transparent;
		--bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e");
	}
	.form-check-input:checked {
		background-color: #12b38c;
		border-color: #12b38c;
		&:focus {
			--bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e");
		}
	}
}
.trx-table {
	--bs-table-bg: transparent;
	.name {
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 113%;
		margin: 0;
	}
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 113%;
	td {
		padding-block: 17px;
		color: rgba(#ffffff, 0.5);
		padding-inline: 0;
		vertical-align: middle;
		border-color: #34343e;
		.btn--secondary {
			border-radius: 5px;
			font-weight: 700;
		}
	}
}
.form--radio {
	display: flex;
	align-items: center;
	gap: 13px;
	cursor: pointer;
	.form-check-input {
		width: 12px;
		height: 12px;
		box-shadow: none !important;
		background: transparent;
		border: 1px solid #88869f;
		&:focus {
			box-shadow: none !important;
		}
		&:checked {
			background-color: #ffffff;
		}
	}
	.form-check-label {
		font-size: 10px;
		font-style: normal;
		font-weight: 400;
		line-height: 113%;
		margin: 0;
	}
	img {
		height: 20px;
	}
}
.language-radio-item {
	border-radius: 6px;
	background: #2a2a37;
	padding: 3px 3px 3px 8px;
	&.style-2 {
		font-size: 16px;
		padding: 8px 12px;
		.form-check-input {
			width: 20px;
			height: 20px;
		}
	}
}
.font-18 {
	font-size: 18px;
}
.text-14 {
	font-size: 14px;
}
.text-white {
	.form--label {
		color: var(--white);
	}
}
.text-16 {
	font-size: 16px !important;
}
.trading-rooms-section {
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	padding: 0 0px 30px 0px;
	@include breakpoint(max-xxl) {
		padding-inline: 24px;
	}
	.rooms-quick-icons {
		@include breakpoint(xl) {
			padding-top: 40px;
		}
		button {
			background: transparent;
			outline: none;
			border: 2px solid transparent;
			outline: 2px solid transparent;
			box-shadow: none;
			border-radius: 8px;
			transition: all ease 0.3s;
			img {
				max-width: 100%;
			}
			&:not(:first-child):hover {
				outline: 2px solid var(--base);
			}
		}
		.inner-div {
			position: sticky;
			top: 94px;
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 22px;
		}
		@include breakpoint(max-md) {
			.inner-div {
				flex-direction: row;
				width: 100%;
				gap: 15px;
				@include breakpoint(max-sm) {
					gap: 8px;
				}
				.btn {
					width: 0;
					flex-grow: 1;
				}
			}
		}
	}
	img {
		max-width: 100%;
	}
	transition: all ease 0.3s;
	&.active {
		@include breakpoint(lg) {
			padding-right: 250px;
		}
		@include breakpoint(xl) {
			padding-right: 290px;
		}
		@include breakpoint(xxl) {
			padding-right: 325px;
		}
	}
}
.trade-rooms-right-sidebar {
	width: 100%;
	.trade-rooms-right-sidebar-top {
		padding: 57px 24px 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 13px;
		margin-bottom: 24px;
	}
	@include breakpoint(lg) {
		max-width: 250px;
	}
	@include breakpoint(xl) {
		max-width: 293px;
	}
	@include breakpoint(xxl) {
		// margin-left: 35px;
		.trade-rooms-right-sidebar-top {
			margin-bottom: 28px;
		}
	}
	.search-form {
		.form-control {
			padding-left: 16px;
		}
		.btn {
			position: absolute;
			right: 8px;
			top: 4px;
			font-weight: 400;
			display: flex;
			align-items: center;
			gap: 4px;
			font-size: 14px;
			height: calc(100% - 8px);
		}
	}
	.form--select {
		font-size: 14px;
		background-color: transparent;
		width: 130px;
		option {
			background-color: var(--section);
		}
	}
	position: fixed;
	right: 0;
	width: 100vw;
	max-width: 320px;
	z-index: 999;
	top: 0;
	background: var(--section);
	padding: 24px;
	height: 100vh;
	overflow-y: auto;
	transition: all ease 0.3s;
	&:not(.active) {
		opacity: 0;
		visibility: hidden;
		transform: translateX(100%);
	}
	&::-webkit-scrollbar {
		width: 0;
	}
	&::-webkit-scrollbar-track {
		background: #1b1b23;
	}
	&::-webkit-scrollbar-thumb {
		background: #88869f;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #88869f;
	}
	// for safari
	& {
		scrollbar-width: none;
		scrollbar-color: transparent transparent;
	}
	@include breakpoint(xl) {
		top: 68px;
		height: calc(100vh - 68px);
		background: transparent;
	}
	@include breakpoint(max-xl) {
	}
}
.h-48 {
	height: 48px;
}
.text-underline {
	text-decoration: underline;
}
.border-base {
	border-color: var(--base) !important;
}
.members-wrapper {
	.members-item {
		&:not(:last-child) {
			margin-bottom: 36px;
		}
		.members-title {
			color: #88869f;
			font-size: 14px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			display: flex;
			align-items: flex-end;
			gap: 8px;
			font-family: var(--body-fonts);
			&::after {
				content: "";
				flex-grow: 1;
				display: inline-block;
				height: 1px;
				background: #88869f;
			}
			margin-bottom: 28px;
		}
		.members-list {
			display: flex;
			flex-direction: column;
			gap: 21px;
			.member {
				display: flex;
				gap: 9px;
				align-items: center;
				img {
					border-radius: 50%;
					width: 34px;
					height: 34px;
					object-fit: cover;
					// border: 1px solid var(--base-2);
				}
				* {
					color: #88869f;
					font-family: var(--body-fonts);
					font-size: 14px;
					font-style: normal;
					font-weight: 600;
				}
			}
		}
	}
}
.trade-rooms-right-siebar-inner {
	position: sticky;
	top: 80px;
	max-height: 100vh;
	overflow-y: auto;
	// hide scrollbar
	&::-webkit-scrollbar {
		width: 0;
	}
	&::-webkit-scrollbar-track {
		background: #1b1b23;
	}
	&::-webkit-scrollbar-thumb {
		background: #88869f;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #88869f;
	}
	// for safari
	& {
		scrollbar-width: none;
		scrollbar-color: transparent transparent;
	}
}
.abs-right {
	position: absolute;
	top: 34px;
	right: 24px;
}
.trading-rooms-wrapper {
	border-radius: 37px;
	background: #14141a;
	flex-grow: 1;
	display: flex;
	.trade-rooms-sidebar {
		border-radius: 37px 0px 0px 37px;
		background: #1b1b23;
		width: 100%;
		max-width: 336px;
		.title-area {
			padding: 30px 34px 0;
		}
		.title {
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			font-family: var(--body-fonts);
			border-bottom: 1px solid rgba(#ffffff, 0.1);
			padding-bottom: 22px;
		}
		.trade-rooms-sidebar-inner {
			position: sticky;
			top: 80px;
			height: 100%;
			display: flex;
			flex-direction: column;
			// overflow-y: auto;
			// max-height: 100vh;
			// // hide scrollbar
			// &::-webkit-scrollbar {
			// 	width: 0;
			// }
			// &::-webkit-scrollbar-track {
			// 	background: #1b1b23;
			// }
			// &::-webkit-scrollbar-thumb {
			// 	background: #88869f;
			// 	border-radius: 10px;
			// }
			// &::-webkit-scrollbar-thumb:hover {
			// 	background: #88869f;
			// }
			// // for safari
			// & {
			// 	scrollbar-width: none;
			// 	scrollbar-color: transparent transparent;
			// }
			.rooms-sidebar-bottom {
				margin-top: auto;
				position: absolute;
  				bottom: 0;
				.top-part {
					padding: 21px;
					display: flex;
					align-items: center;
					gap: 8px;
					border-radius: 22px 22px 0 0;
					background: #000;
					box-shadow: 0px -44px 63px 0px rgba(5, 5, 5, 0.3);
					.subtitle {
						font-size: 16px;
						font-style: normal;
						font-weight: 700;
						line-height: normal;
						letter-spacing: 0.8px;
						margin: 0;
						font-family: var(--body-fonts);
					}
					.cmn-btn {
						padding-inline: 15px;
					}
				}
				.bottom-part {
					padding: 27px 14px;
				}
			}
		}
		@include breakpoint(max-xl) {
			max-width: 300px;
			// decrease font sizes
			position: fixed;
			z-index: 999;
			top: 0;
			left: 0;
			.title {
				font-size: 18px;
			}
			.subtitle {
				font-size: 14px;
			}
			transition: all ease 0.3s;
			&:not(.active) {
				opacity: 0;
				visibility: hidden;
				transform: translateX(-100%);
			}
		}
	}
	.trade-rooms-area {
		flex-grow: 1;
		width: 200px;
		.trade-rooms-area-header {
			padding: 24px;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-between;
			gap: 20px;
			border-radius: 0px 37px 0px 0px;
			background: #1e1e27;
			@include breakpoint(max-xl) {
				border-radius: 37px 37px 0px 0px;
			}
			.authors {
				display: flex;
				align-items: center;
				gap: 8px;
				margin-right: auto;
			}
		}
	}
}
.post--item {
	padding: 26px;
	display: flex;
	gap: 19px;
	@include breakpoint(max-sm) {
		padding: 16px;
	}
	.img {
		width: 59px;
		img {
			width: 100%;
			border-radius: 50%;
		}
		@include breakpoint(max-md) {
			width: 42px;
		}
	}
	.cont {
		width: 0;
		flex-grow: 1;
		.name {
			font-size: 18px;
			font-style: normal;
			font-weight: 600;
			.time {
				color: #88869f;

				font-family: var(--body-fonts);
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
			}
		}
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 22px;
		.reply {
			margin: 0;
			padding-left: 16px;
			position: relative;
			&::before {
				content: "";
				border-radius: 5px;
				background: var(--base);
				display: block;
				width: 3px;
				height: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
			}
			span {
				font-weight: 600;
			}
		}
	}
}
.reaction-icons {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	.btn {
		border-radius: 29px;
		border: 1px solid transparent !important;
		&:focus,
		&:active {
			border-color: var(--base) !important;
		}
	}
}
.bg--section-2 {
	background: var(--section-2);
}
.badge--warning {
	color: #000;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	background: #ffc100;
	border-radius: 29px;
	font-family: var(--body-fonts);
}
.badge-base {
	color: #ffffff;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	background: var(--base);
	border-radius: 29px;
	font-family: var(--body-fonts);
}
.news-stream {
	max-width: 498px;
	display: block;
	border-radius: 20px;
	border: 1px solid rgba(#ffffff, 0.1);
	img {
		width: 100%;
		border-radius: 20px 20px 0px 0px;
	}
	.tag {
		font-size: 10px;
		font-style: normal;
		font-weight: 600;
		margin-bottom: 5px;
		color: var(--text);
		display: inline-flex;
		align-items: center;
		gap: 8px;
		&::before {
			content: "";
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background: var(--base);
		}
	}
	h6 {
		font-size: 18px;
		font-weight: 600;
	}
}
.text-link {
	color: #85d4ff;
}
.bg--warning-light {
	background: rgba(254, 241, 137, 0.04);
	&.border-left {
		border-left: 5px solid #f90 !important;
	}
}
.comments-form {
	.form-control-2 {
		border-radius: 10px;
		background: #282834;
		height: 46px;
		padding-left: 58px;
		padding-right: 137px;
		font-size: 14px;

	}
}
.comments-form {
	position: relative;
	.add-file-btn {
		left: 15px;
		top: 0;
	}
	.emoji-icon,
	.gif-icon,
	.add-file-btn,
	.sticker-icon {
		height: 46px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
	}
	.gif-icon {
		right: 45px;
	}
	.emoji-icon {
		right: 12px;
		left: unset;
	}
	.sticker-icon {
		right: 85px;
	}
	.emoji-picker--open {
		position: absolute;
		// left: 10px;
		// bottom: 10px;
	}
}
.emoji--picker-12:not(.emoji-picker--open) {
	opacity: 0;
	visibility: hidden;
	transform: translateY(20px);
}
.trade-rooms-menu {
	padding: 10px 17px 20px;
	.menu-item {
		button {
			color: #88869f;
		}
		&.initialopen {
			.parent-menu-link {
				.caret {
					transform: rotate(180deg);
				}
			}
		}
		.parent-menu-link {
			padding: 10px 17px;
			border-radius: 8px;
			color: #88869f;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			//&.active {
			//	padding: 14px 17px;
			//	color: var(--white);
			//	background: #cb2228;
			//	background: linear-gradient(268deg, #ff377f 0%, #cb2228 100%);
			//	.caret {
			//		transform: rotate(180deg);
			//	}
			//	button {
			//		color: #ffffff;
			//	}
			//}
		}
		.parent-menu-link-active {
			padding: 10px 17px;
			border-radius: 8px;
			color: #88869f;
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
			padding: 14px 17px;
			color: var(--white);
			background: #cb2228;
			background: linear-gradient(268deg, var(--base-2) 0%, var(--base) 100%);
			.caret {
				transform: rotate(180deg);
			}
			button {
				color: #ffffff;
			}
		}
		.submenu {
			.submenu-inner {
				padding-block: 10px;
			}
			.submenu-item {
				border-radius: 8px;
				color: #88869f;
				font-size: 15px;
				a {
					color: #88869f;
					border-radius: 6px;
					padding: 6px 17px 6px 6px;
					&.active {
						color: var(--white);
						background: linear-gradient(268deg, #ff377f 0%, #cb2228 100%);
						background: #1e1e27;
					}
				}
				.members-count {
					color: #88869f;
					padding: 1px 6px;
					border-radius: 5px;
					background: #34343e;
					font-size: 12px;
				}
			}
			.submenu-item-active {
				border-radius: 8px;
				color: var(--white);;
				font-size: 15px;
				a {
					color: var(--white);;
					border-radius: 6px;
					padding: 6px 17px 6px 6px;
				}
				.members-count {
					color: #88869f;
					padding: 1px 6px;
					border-radius: 5px;
					background: #34343e;
					font-size: 12px;
				}
			}
		}
	}
	@include breakpoint(max-xl) {
		// decrease font sizes
		.menu-item {
			.parent-menu-link {
				font-size: 16px;
			}
			.submenu {
				.submenu-item {
					font-size: 14px;
				}
			}
		}
	}
}
.gap-14px {
	gap: 14px;
}

.rhap_container {
	padding: 0 !important;
	background: transparent !important;
	align-items: center;
	.rhap_progress-container {
		margin-left: 0;
		margin-right: 50px;
		order: -1;
	}
	.rhap_current-time {
		&::after {
			content: "/";
			display: inline-block;
			margin: 0 4px;
		}
	}
	.rhap_main-controls-button {
		color: var(--base) !important;
	}
	.rhap_time {
		color: var(--white) !important;
	}
	.rhap_main {
		padding: 0;
		flex-direction: row;
		width: 100%;
		.rhap_controls-section {
			flex-grow: 0;
			margin: 0;
		}
		.rhap_main-controls {
			margin-left: -143px;
			.rhap_rewind-button,
			.rhap_forward-button {
				display: none;
			}
		}
	}
	@include breakpoint(max-sm) {
		.rhap_time {
			font-size: 12px;
		}
		.rhap_progress-container {
			margin-right: 8px;
		}
		.rhap_main {
			.rhap_main-controls {
				margin-left: 0;
			}
		}
	}
}
.rhap_progress-indicator,
.rhap_progress-filled {
	background: var(--base) !important	;
}
.bg-14141a {
	background: #14141a;
}
.form-check {
	.text-base.base-2 {
		--base: #51a4ff;
	}
}
.text-34343E {
	font-weight: 500;
	color: #34343e;
	&::after,
	&::before {
		background: #34343e;
		height: 1px;
	}
	@include breakpoint(xxl) {
		gap: 50px;
	}
}
.post-reacts-tooltip {
	position: absolute;
	top: 0;
	right: 20px;
	transform: translateY(-50%);
	.no-gutter {
		background: transparent;
		&::after {
			display: none;
		}
	}
	gap: 10px;
	border-radius: 10px;
	background: #34343e;
	z-index: 1;
	padding: 7px 22px;
	display: flex;
	align-items: center;
	svg {
		max-width: 20px;
		max-height: 20px;
	}
	.no--gutter {
		svg {
			color: var(--title);
		}
	}
	.no-gutter {
		background: transparent !important;
	}
}
.no-gutter {
	background-color: transparent !important;
	&.dropdown-toggle {
		&::after {
			display: none;
		}
	}
}
.post--item {
	position: relative;
}
.dropd-link {
	color: var(--white);
	&:hover {
		color: var(--base);
	}
}
.reply-item {
	.reply-content-area {
		padding-left: 40px;
		.normal-text {
			font-size: 10px;
		}
	}
}
.cate-title-header {
	font-weight: 600;
	h5 {
		font-size: 24px;
		font-weight: 600;
	}
	.form-control-2 {
		font-weight: 600;
		color: #88869f;
	}
}
.my-course-title {
	margin-bottom: 30px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	h6 {
		font-weight: 600;
		font-size: 24px;
		margin: 0;
	}
	@include breakpoint(max-sm) {
		h6 {
			font-size: 20px;
		}
	}
}
.course-card {
	border-radius: 20px;
	background: var(--border);
	display: block;
	.course-card-img {
		position: relative;
		overflow: hidden;
		border-radius: 20px 20px 0 0;
		img {
			height: 208px;
			width: 100%;
			object-fit: cover;
			border-radius: 20px 20px 0 0;
			transition: all ease 0.3s;
		}
		.__badge {
			position: absolute;
			right: 17px;
			top: 17px;
			border-radius: 5px;
			background: rgba(0, 0, 0, 0.5);
			padding: 4px 8px;
			strong {
				padding-inline: 10px;
				color: #37ff7b;
				font-family: var(--heading-fonts);
				text-align: center;
				font-size: 15px;
				font-style: normal;
				font-weight: 700;
			}
			span {
				color: #ffffff;
				font-size: 15px;
				font-style: normal;
				font-weight: 500;
				line-height: 18px;
			}
			&.warning {
				strong {
					color: #ffaa54;
				}
			}
		}
	}
	.course-card-title {
		font-weight: 600;
		font-size: 18px;
		margin-bottom: 10px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	.course-card-body {
		color: #e0e0e0;
		padding: 20px 17px;
		h6 {
			font-size: 20px;
			font-weight: 700;
			transition: all ease 0.3s;
			margin: 0;
			margin-bottom: 7px;
			@include breakpoint(max-sm) {
				font-size: 18px;
				font-weight: 600;
			}
		}
		p {
			font-weight: 700;
			font-size: 16px;
			margin: 0;
		}
	}
	&:hover {
		img {
			transform: scale(1.09);
		}
		h6 {
			color: var(--base);
		}
	}
}
.text-hover {
	color: #88869f;
	&:hover {
		color: var(--base);
	}
}
.exciting-feature-card {
	border-radius: 20px;
	background: #212128;
	padding: 45px 31px 31px;
	.subtext {
		font-size: 20px;
		font-style: normal;
		font-weight: 500;
		margin-bottom: 22px;
	}
	.title {
		max-width: 445px;
		min-height: 160px;
		margin-bottom: 29px;
	}
	button {
		color: #e0e0e0;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
	}
	@include breakpoint(max-sm) {
		padding: 30px 18px;
		.subtext {
			font-size: 16px;
		}
		.title {
			min-height: 0;
			font-weight: 500;
		}
		button {
			font-size: 14px;
		}
	}
}
.breadcrumb {
	display: flex;
	flex-wrap: wrap;
	li {
		&:not(:last-child)::after {
			content: ">";
			display: inline-block;
			margin: 0 5px;
		}
		font-size: 15px;
		font-style: normal;
		font-weight: 700;
		color: var(--base);
		a {
			color: var(--base);
		}
	}
	padding: 0;
	margin-bottom: 20px;
}
.course-banner {
	margin-inline: -20px;
	padding-inline: 20px;
	@include breakpoint(xxl) {
		margin-inline: -28px;
		padding-inline: 28px;
	}
	@media screen and (min-width: 1650px) {
		margin-right: -57px;
		padding-left: 45px;
		padding-right: 57px;
	}
	padding-block: 42px 31px;
	.course-banner-inner {
		max-width: 764px;
	}
	.title {
		font-size: 40px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: 2px;
		margin-bottom: 20px;
	}
	color: #e0e0e0;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	.badge {
		padding: 3px 9px;
		color: #37ff7b;
		font-family: var(--heading-fonts);
		text-align: center;
		font-size: 20px;
		border-radius: 5px;
		background: rgba(55, 255, 123, 0.1);
		margin-bottom: 28px;
	}
	.meta {
		a {
			color: #e0e0e0;
			font-size: 20px;
			font-style: normal;
			font-weight: 700;
			margin-left: 8px;
		}
	}
	@include breakpoint(max-xl) {
		.title {
			font-size: 30px;
		}
		.meta {
			a {
				font-size: 18px;
			}
		}
		font-size: 16px;
		.badge {
			font-size: 16px;
			font-weight: 500;
			margin-bottom: 15px;
		}
	}
	@include breakpoint(max-md) {
		padding-bottom: 200px;
	}
}
.course-summary {
	@include breakpoint(md) {
		position: absolute;
		width: 100%;
		left: 0;
		top: 0;
	}
	img {
		height: auto;
		width: 100%;
		aspect-ratio: 16 / 9;
		object-fit: cover;
		border-radius: 16px 16px 0 0;
		@include breakpoint(max-md) {
			height: 208px;
		}
	}
	border: 5px solid #212128;
	background: #0e0e11;
	border-radius: 16px;
	padding-bottom: 25px;
	.price {
		padding: 37px 0px 36px;
		text-align: center;
		.previous {
			font-size: 20px;
			font-style: normal;
			font-weight: 400;
		}
		.current {
			font-size: 30px;
			font-style: normal;
			font-weight: 600;
		}
	}
	.cmn-btn {
		font-size: 20px;
		height: 50px;
		width: 100%;
		@include breakpoint(mxl) {
			height: 67px;
		}
	}
	.btn-outline:not(:hover) {
		color: var(--base);
	}
	@include breakpoint(max-xl) {
		@include breakpoint(lg) {
			.price {
				padding: 20px 0px;
				.current {
					font-size: 24px;
				}
			}
			.cmn-btn {
				font-size: 16px;
			}
			img {
				height: 180px;
			}
		}
	}
	@include breakpoint(max-md) {
		max-width: 500px;
		margin: 0 auto;
	}
}
.order-summary-content {
	padding-inline: 20px;
	@include breakpoint(xl) {
		padding-inline: 40px;
	}
	.subtitle {
		font-size: 15px;
		font-style: normal;
		font-weight: 800;
		margin-bottom: 12px;
	}
	.includes {
		display: flex;
		flex-direction: column;
		gap: 12px;
		li {
			display: flex;
			gap: 13px;
			align-items: flex-start;
			line-height: 1.5;
			font-size: 15px;
			span {
				width: 0;
				flex-grow: 1;
			}
			&::before {
				content: "";
				width: 13px;
				height: 13px;
				display: inline-block;
				background: #d9d9d9;
				transform: translateY(5px);
			}
		}
	}
}
.plan-summary-content {
	padding-inline: 20px;
	@include breakpoint(xl) {
		padding-inline: 40px;
	}
	.subtitle {
		font-size: 15px;
		font-style: normal;
		font-weight: 800;
		margin-bottom: 12px;
	}
	.includes {
		display: flex;
		flex-direction: column;
		gap: 20px;
		li {
			display: flex;
			gap: 13px;
			align-items: flex-start;
			line-height: 1.5;
			font-size: 15px;
			span {
				width: 0;
				flex-grow: 1;
			}
			&::before {
				content: "";
				width: 13px;
				height: 13px;
				display: inline-block;
				background: #d9d9d9;
				transform: translateY(5px);
			}
			h5 {
				//width: 0;
				//flex-grow: 1;
			}
			&::before {
				content: "";
				width: 13px;
				min-width: 13px;
				min-height: 13px;
				height: 13px;
				display: inline-block;
				background: #d9d9d9;
				transform: translateY(5px);
			}
		}
	}
}
.mb-20 {
	margin-bottom: 20px;
}
.fz-15 {
	font-size: 15px;
}
.course-preview-accordion {
	@media screen and (min-width: 1650px) and (max-width: 1750px) {
		max-width: 861px;
		margin-left: 20px;
	}
	.accordion {
		border-radius: 10px;
		background: #212128;
		--bs-accordion-bg: #212128;
		border: none;
		display: flex;
		flex-direction: column;
		gap: 9px;
		.accordion-item {
			border: none;
		}
	}
	.accordion-button {
		border-radius: 10px;
		background: #30303a;
		color: #e0e0e0;
		font-size: 20px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		border: none !important;
		outline: none !important;
		box-shadow: none !important;
		@include breakpoint(xxl) {
			min-height: 79px;
		}
		&::after {
			display: none;
		}
	}
	.sub-data {
		li {
			a {
				display: flex;
				// flex-wrap: wrap;
				padding: 8px 0;
				gap: 15px;
				font-weight: 600;
				font-size: 15px;
				color: #e0e0e0;
				padding-inline: 15px;
				.text-base {
					text-decoration: underline;
				}
				&.active {
					background: #30303a;
					border-radius: 8px;
					color: #ffffff;
				}
			}
		}
	}
}
.course-preview-sidebar-accordion {
	max-height: 524px;
	padding-right: 6px;
	overflow-y: auto;
	// scrollbar
	&::-webkit-scrollbar {
		width: 4px;
	}
	&::-webkit-scrollbar-track {
		background: #1b1b23;
	}
	&::-webkit-scrollbar-thumb {
		background: #88869f;
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: #88869f;
	}
	// for safari
	& {
		scrollbar-width: thin;
		scrollbar-color: transparent transparent;
	}
	.accordion-button {
		min-height: 57px;
		font-size: 15px;
	}
	.accordion-body {
		padding-inline: 10px;
	}
	.sub-data {
		li {
			a:not(.active) {
				> span:first-child {
					color: rgba(#e0e0e0, 0.5);
				}
			}
		}
	}
}
.instructor-info {
	color: #88869f;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: 113%;
	.subtitle {
		color: #e0e0e0;
		font-size: 15px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
	}
	.name-area {
		.name {
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			margin-bottom: 5px;
		}
	}
}
.preview-wrapper {
	border-radius: 10px;
	background: #282834;
	.preview-video {
		border-radius: 7px;
		background: #1b1b23;
		overflow: hidden;
		iframe {
			display: flex;
			@include breakpoint(max-sm) {
				height: 360px;
			}
		}
	}
	.nav-menu {
		padding-inline: 16px;
		@include breakpoint(sm) {
			padding-inline: 30px;
		}
		@include breakpoint(max-sm) {
			padding-block: 15px;
		}
		li {
			a {
				color: rgba(#e0e0e0, 0.5);
				font-weight: 600;
				padding-block: 14px;
				&.active {
					color: #e0e0e0;
				}
				&::before {
					bottom: 0;
					top: unset;
					left: 0;
					width: 100%;
					background: #e0e0e0;
				}
			}
		}
	}
}
.preview-title {
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	margin: 0;
	@include breakpoint(xxl) {
		font-size: 25px;
	}
}
.text-E0E0E050 {
	color: rgba(#e0e0e0, 0.5);
}
.course-completed-modal {
	.modal-dialog {
		max-width: 1055px;
		.modal-content {
			border-radius: 25px;
			background: #292930;
			box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.25);
			@include breakpoint(xl) {
				padding-top: 25px;
				padding-inline: 25px;
			}
			@include breakpoint(xxl) {
				padding-top: 45px;
			}
			.title {
				margin-bottom: 18px;
				@include breakpoint(xxl) {
					font-size: 49px;
				}
			}
			.subtitle {
				text-align: center;
				font-size: 18px;
				font-family: var(--body-fonts);
				font-style: normal;
				font-weight: 600;
				line-height: normal;
			}
			.course-completed-header {
				max-width: 532px;
				margin: 0 auto 37px;
			}
			.cmn-btn {
				height: 55px;
				font-size: 18px;
				width: 100%;
				max-width: 362px;
				margin: 0 auto;
				@include breakpoint(xxl) {
					height: 67px;
					font-size: 20px;
				}
			}
		}
	}
}
.course-completed-info {
	display: flex;
	flex-wrap: wrap;
	gap: 29px;
	padding-inline: 17px;
	padding-top: 17px;
	position: relative;
	&::before {
		content: "";
		top: 0;
		left: 0;
		right: 0;
		background: #42424c;
		border-radius: 20px;
		bottom: 60px;
		position: absolute;
	}
	.img {
		width: 100%;
		max-width: 432px;
		position: relative;
		z-index: 1;
		border-radius: 15px;
		object-fit: cover;
	}
	.content {
		width: 0;
		flex-grow: 1;
		position: relative;
		z-index: 1;
		.content-top {
			max-width: 403px;
			padding: 33px 0;
			.sub-text {
				font-size: 25px;
				font-style: normal;
				font-weight: 700;
				margin-bottom: 7px;
			}
			.name {
				font-size: 15px;
				font-weight: 600;
				display: block;
				margin-bottom: 9px;
			}
			blockquote {
				color: #88869f;
				font-size: 12px;
				font-style: normal;
				font-weight: 600;
			}
		}
	}
	@include breakpoint(max-lg) {
		.img {
			max-width: 300px;
		}
	}
	@include breakpoint(max-md) {
		.img {
			max-width: 250px;
		}
		.content {
			.content-top {
				padding-top: 14px;
				.sub-text {
					font-size: 20px;
					font-weight: 500;
				}
			}
		}
	}
	@include breakpoint(max-sm) {
		.img {
			max-width: 100%;
		}
		.content {
			width: 100%;
			.content-top {
				padding-top: 0;
				.sub-text {
					font-size: 18px;
				}
			}
		}
		.cmn-btn {
			font-size: 16px;
			height: 50px;
		}
	}
}
.community-item-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	.community-item {
		width: calc(20% - 9px);
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			transition: all ease 0.3s;
			object-fit: cover;
		}
		position: relative;
		border-radius: 7px;
		&::before {
			content: "";
			inset: 0;
			position: absolute;
			border-radius: 7px;
			background: linear-gradient(
				180deg,
				rgba(14, 14, 17, 0) 0%,
				rgba(14, 14, 17, 0.7) 100%
			);
			z-index: 1;
		}
		.community-item-title {
			position: absolute;
			z-index: 1;
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: 113%; /* 27.12px */
			letter-spacing: 1.2px;
			font-family: var(--heading-fonts);
			bottom: 28px;
			left: 0;
			width: 100%;
			padding-left: 20px;
			max-width: 160px;
			color: var(--white);
			transition: all ease 0.3s;
			@include breakpoint(max-xxl) {
				@include breakpoint(xl) {
					font-size: 20px;
				}
			}
		}
		&:hover {
			img {
				transform: scale(1.1);
			}
			.community-item-title {
				color: var(--base);
			}
		}
		@include breakpoint(max-xl) {
			width: calc((100% / 3) - 9px);
		}
		@include breakpoint(max-md) {
			.community-item-title {
				font-size: 20px;
			}
		}
		@include breakpoint(max-sm) {
			width: calc((100% / 2) - 9px);
			.community-item-title {
				font-size: 18px;
				padding-left: 15px;
				bottom: 18px;
			}
		}
	}
}
.stream-back-btn {
	height: 67px;
	border-radius: 0px 17px 17px 0px;
	background: #1e1e27;
	@include breakpoint(max-xl) {
		height: 52px;
	}
}
.audiences-menu {
	padding: 10px 29px 0;
	.audience-item {
		.subtitle {
			color: #fff;
			font-family: var(--body-fonts);
			font-size: 10px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			margin-bottom: 15px;
		}
		.audience-list {
			.media {
				display: flex;
				gap: 9px;
				.avatar {
					width: 28px;
					img {
						width: 100%;
						aspect-ratio: 1;
						border-radius: 50%;
					}
				}
				.media-body {
					color: #88869f;
					font-family: var(--poppins);
					font-size: 10px;
					font-style: normal;
					font-weight: 400;
					line-height: 113%;
					width: 0;
					flex-grow: 1;
					h6 {
						color: #fff;
						font-size: 12px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						letter-spacing: 0.6px;
						margin: 0;
					}
				}
				.status {
					.active,
					.inactive {
						position: absolute;
						right: 0;
						top: 6px;
						color: #fff;
						text-align: center;
						font-family: var(--heading-fonts);
						font-size: 10px;
						font-style: normal;
						font-weight: 600;
						line-height: normal;
						letter-spacing: 0.5px;
					}
					.active {
						&::before {
							content: "";
							width: 7px;
							height: 7px;
							border-radius: 50%;
							background: #37ff7b;
							display: inline-block;
							margin-right: 5px;
						}
					}
					.inactive {
						&::before {
							content: "";
							width: 7px;
							height: 7px;
							border-radius: 50%;
							background: #88869f;
							display: inline-block;
							margin-right: 5px;
						}
					}
				}
				position: relative;
			}
		}
		&:not(:last-child) {
			padding-bottom: 10px;
		}
	}
}
.community-item-wrapper {
	display: flex;
}
.livestream-wrapper {
	display: flex;
	gap: 16px;
	margin-inline: -20px;
	@include breakpoint(xxl) {
		margin-inline: -28px;
	}
	@media screen and (min-width: 1650px) {
		margin-right: -57px;
	}
	@include breakpoint(max-xl) {
		flex-wrap: wrap;
	}
	.livestream-area {
		.livestream-body {
			max-height: 1000px;
			overflow: hidden;
			.collapse-icon {
				position: absolute;
				top: 20px;
				right: 20px;
				z-index: 9;
			}
			&.fullscreen {
				max-height: calc(100vh - 90px);
				height: calc(100vh - 90px);
			}
			position: relative;
			.streamer-informations {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				padding-inline: 20px;
				@include breakpoint(xxl) {
					padding-inline: 40px;
				}
				.text-base {
					color: var(--white) !important;
				}
				.author {
					.img {
						img {
							border-color: var(--white);
						}
						.badge-live {
							background: #fff;
						}
					}
				}
			}
		}
		width: 0;
		flex-grow: 1;
		@include breakpoint(max-xl) {
			width: 100%;
		}
	}
	.livestream-right-sidebar {
		width: 100%;
		max-width: 419px;
		@media screen and (max-width: 1650px) {
			max-width: 362px;
		}
		@media screen and (max-width: 1399px) {
			max-width: 300px;
		}
		@include breakpoint(max-xl) {
			// position: fixed;
			// top: 80px;
			// right: 0;
			// width: 100%;
			max-width: 100%;
			// background: var(--section);
			// z-index: 999;
		}
	}
	.description {
		border-radius: 5px;
		background: #282834;
		padding: 19px 27px;
		color: rgba(#fff, 0.5);
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 113%;
		margin-top: 30px;
		h6 {
			margin: 0;
		}
		.form-control {
			background: transparent !important;
			padding: 0;
			font-size: 12px;
			resize: none;
		}
	}
}
.object-cover {
	object-fit: cover;
}
.object-contain {
	object-fit: contain;
}
.streamer-informations {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 24px;
	margin-top: 16px;
	.author {
		align-items: center;
		display: flex;
		gap: 16px;
		.img {
			width: 74px;
			height: 74px;
			img {
				width: 100%;
				border-radius: 50%;
				border: 2px solid var(--base);
			}
			position: relative;
			.badge-live {
				position: absolute;
				bottom: 0;
				left: 50%;
				color: #0e0e11;
				text-align: center;
				font-family: var(--poppins);
				font-size: 10px;
				font-style: normal;
				font-weight: 700;
				line-height: 113%;
				padding: 5px 15px 4px;
				border-radius: 5px;
				background: linear-gradient(180deg, #ff377f 0%, #c1225b 100%);
				transform: translateX(-50%);
			}
		}
		.cont {
			font-size: 15px;
			font-style: normal;
			font-weight: 400;
			.name {
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: 1px;
				color: #fff;
				margin-bottom: 4px;
			}
		}
		@include breakpoint(max-xxl) {
			@include breakpoint(xl) {
				.cont {
					font-size: 12px;
					.name {
						font-size: 16px;
					}
				}
			}
		}
	}
}
.text-282834 {
	color: #282834;
}
.text-hover-base {
	&:hover {
		color: var(--base);
	}
}
.livestream-right-sidebar {
	.live-stream-title {
		text-align: center;
		background: #1b1b23;
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		color: var(--white);
		padding: 16px;
		position: relative;
		.collapse-icon {
			position: absolute;
			left: 16px;
			top: 16px;
		}
	}
	.live-stream-body {
		flex-grow: 1;
		height: 50px;
		overflow-y: auto;
		padding: 10px 20px;
		background: #1b1b23;
		// scrollbar
		&::-webkit-scrollbar {
			width: 4px;
		}
		&::-webkit-scrollbar-track {
			background: #1b1b23;
		}
		&::-webkit-scrollbar-thumb {
			background: #88869f;
			border-radius: 10px;
		}
		&::-webkit-scrollbar-thumb:hover {
			background: #88869f;
		}
		// for safari
		& {
			scrollbar-width: thin;
			scrollbar-color: transparent transparent;
		}
	}
	height: calc(100vh - 90px);
	display: flex;
	flex-direction: column;

	.live-stream-footer {
		background: #282834;
		padding: 22px 20px;
		.form-control-2 {
			border-radius: 10px;
			background: #3c3c4d;
			height: 56px;
			font-size: 17px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			letter-spacing: 0.85px;
			color: #78768a;

			font-family: var(--heading-fonts);
		}
		.emoji-icon {
			height: 56px;
		}
		.chat-btn {
			color: #1b1b23;
			text-align: center;
			font-family: "Clash Display";
			width: 102px;
			height: 56px;
			font-size: 20px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 1px;
			border-radius: 10px;
			background: #37ff7b;
			outline: none;
			box-shadow: none;
			border: none;
		}
		.slow-mode {
			color: #88869f;
			font-size: 15px;
			font-family: "Clash Display";
			letter-spacing: 0.75px;
		}
		.btn--secondary {
			border-radius: 5px;
			background: #3c3c4d;
			height: 32px;
			border: none;
			display: flex;
			align-items: center;
			justify-content: center;
			gap: 5px;
			color: #78768a;
			font-weight: 500;
			font-family: Clash Display;
			max-width: 200px;

			@media screen and (max-width: 1650px) {
				font-size: 12px;
				gap: 3px;
			}
		}
	}
	@include breakpoint(md) {
		&.active {
			display: none;
		}
	}
	@include breakpoint(max-md) {
		transition: all ease 0.3s;
		&:not(.active) {
			// transform: translateX(100%);
			display: none;
		}
	}
}
.streaming-comments {
	ul {
		li {
			font-size: 12px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.6px;
			font-family: var(--heading-fonts);
			&:not(:last-child) {
				margin-bottom: 14px;
			}
			.name {
				margin-right: 9px;
			}
		}
	}
}
.gap-9px {
	gap: 9px;
}
.top-streaks {
	max-width: 291px;
	.title {
		color: #fff;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 113%;
		letter-spacing: 1.2px;
		margin-bottom: 20px;
	}
	.subtext {
		color: #88869f;
		font-family: "Open Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 15px;
	}
}
.top-streaks-list {
	display: flex;
	flex-direction: column;
	gap: 15px;
	.top-streaks-item {
		display: flex;
		align-items: center;
		gap: 12px;
		.serial {
			color: #fff;
			font-family: "Clash Display";
			font-size: 15px;
			font-style: normal;
			font-weight: 600;
			line-height: 113%;
			letter-spacing: 0.75px;
		}
		img {
			object-fit: cover;
			border-radius: 50%;
		}
		.top-streaks-item-content {
			width: 0;
			flex-grow: 1;
			.name {
				color: #fff;
				font-family: "Clash Display";
				font-size: 15px;
				font-style: normal;
				font-weight: 600;
				line-height: 113%;
				letter-spacing: 0.75px;
			}
		}
	}
}
.mb-40 {
	margin-bottom: 40px;
}
.discussions-articles-streaks {
	.title {
		color: #fff;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: 113%;
		letter-spacing: 1.2px;
		margin-bottom: 10px;
	}
	.subtext {
		color: #88869f;
		font-family: "Open Sans";
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 15px;
		margin-bottom: 19px;
	}
}
.discussions-articles-streaks-list {
	display: flex;
	flex-direction: column;
	gap: 6px;
	.discussions-articles-streaks-item {
		padding: 14px 14px 14px 0;
		display: flex;
		position: relative;
		&::before {
			content: "";
			position: absolute;
			inset: 0;
			left: -28px;
			background: #1b1b23;
			opacity: 0;
			transition: all ease 0.3s;
			@include breakpoint(max-xxl) {
				inset-inline: -20px;
			}
			z-index: -1;
		}
		.discussions-articles-streaks-item-img {
			width: 60px;
			img {
				width: 100%;
				object-fit: cover;
				border-radius: 50%;
				aspect-ratio: 1;
			}
		}
		gap: 25px;
		.discussions-articles-streaks-item-content {
			width: 0;
			flex-grow: 1;
			.title {
				color: #88869f;
				font-family: "Open Sans";
				font-size: 20px;
				font-style: normal;
				font-weight: 400;
				line-height: 113%;
				letter-spacing: 1px;
			}
			p {
				margin: 0;
			}
		}
		&:hover {
			&::before {
				opacity: 1;
			}
			.title {
				color: var(--white);
				font-weight: 800;
				&::before {
					position: absolute;
					content: "";
					left: -16px;
					top: 8px;
					width: 6px;
					height: 6px;
					object-fit: cover;
					border-radius: 50%;
					background: var(--white);
				}
				position: relative;
			}
		}
		padding-right: 74px;
		.discussions-articles-streaks-item-avatar {
			position: absolute;
			right: 14px;
			top: 14px;
			img {
				width: 39px;
				aspect-ratio: 1;
				object-fit: cover;
				border-radius: 50%;
				position: relative;
				&:not(:last-child) {
					margin-right: -25px;
				}
				&:nth-child(1) {
					z-index: 2;
				}
				&:nth-child(2) {
					z-index: 1;
				}
			}
		}
		@include breakpoint(max-xl) {
			.discussions-articles-streaks-item-content {
				.title {
					font-size: 18px;
				}
			}
		}
		@include breakpoint(max-sm) {
			gap: 20px;
			.discussions-articles-streaks-item-img {
				width: 40px;
			}
			.discussions-articles-streaks-item-content {
				.title {
					font-size: 16px;
				}
			}
			padding-right: 50px;
			.discussions-articles-streaks-item-avatar {
				right: 0;
				img {
					width: 30px;
				}
			}
		}
	}
}
.discussion-articles-row {
	@media screen and (min-width: 1650px) and (max-width: 1850px) {
		.col-xl-8 {
			width: 69.2%;
		}
		.col-xl-4 {
			width: calc(100% - 69.2%);
		}
	}
}
.trading-page-header {
	.title {
		color: #fff;
		font-size: 24px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 1.2px;
		margin-bottom: 10px;
	}
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: 113%;
	letter-spacing: 0.75px;
}
.mb-27 {
	margin-bottom: 27px;
}
.pinned-card-img-area {
	display: flex;
	img {
		width: 100%;
	}
	gap: 15px;
	&.active {
		flex-wrap: wrap;
	}
	.pinned-card-img {
		width: calc(25% - 12px);
		position: relative;
		cursor: pointer;
		.pinned-card-img-more {
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			border-radius: 10px;
			background: linear-gradient(
					0deg,
					rgba(33, 33, 40, 0.322) 0%,
					rgba(33, 33, 40, 0.336) 100%
				),
				rgba(211, 211, 211, 0) 50% / cover no-repeat;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			@include breakpoint(md) {
				color: #fff;
				font-family: Open Sans;
				font-size: 30px;
				font-style: normal;
				font-weight: 800;
				line-height: normal;
			}
		}
	}
}
.reply-text-sm {
	font-size: 10px;
	.normal-text {
		font-size: 10px;
	}
	.text-16 {
		font-size: 15px !important;
	}
}
.text-88869F {
	color: #88869f;
}
.communities-item-2 {
	display: flex;
	gap: 23px;
	align-items: center;
	justify-content: space-between;
	font-size: 15px;
	img {
		width: 59px;
		height: 59px;
		object-fit: cover;
		border-radius: 50%;
	}
	.subtitle {
		font-size: 15px;
		font-family: var(--body-fonts);
		margin-bottom: 8px;
	}
	.btn {
		font-size: 15px;
	}
}
.sidebar-communities-subtitle-2 {
	color: #88869f;
	font-family: var(--body-fonts);
	font-size: 15px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding-inline: 29px;
}
.sidebar-communities-2 {
	padding-right: 29px;
	display: flex;
	flex-direction: column;
	gap: 3px;
	a {
		padding: 11px 29px;
		display: flex;
		align-items: center;
		gap: 13px;
		width: 100%;
		.cont {
			width: 0;
			flex-grow: 1;
			.name {
				color: #fff;
				font-family: var(--body-fonts);
				font-size: 15px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				margin: 0;
			}
			small {
				color: #88869f;
				font-size: 10px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
			}
		}
		&.active {
			border-radius: 0px 17px 17px 0px;
			background: #1e1e27;
		}
	}
}
.forex-trading-banner {
	margin-inline: -20px;
	@include breakpoint(xxl) {
		margin-inline: -28px;
	}
	@media screen and (min-width: 1650px) {
		margin-right: -57px;
	}
	img {
		width: 100%;
		height: 259px;
		object-fit: cover;
	}
}
.forex-thumbnail {
	width: 155px;
	height: 155px;
	margin-top: -125px;
	position: relative;
	z-index: 1;
	border: 2px solid var(--base);
	border-radius: 6px;
	object-fit: cover;
}
.forex-title {
	@include breakpoint(md) {
		color: #fff;
		font-family: "Clash Display";
		font-size: 35px;
		font-style: normal;
		font-weight: 600;
		line-height: 113%;
		letter-spacing: 1.75px;
	}
}
.bg-212128 {
	background: #212128 !important;
}
select.no-gutter {
	padding: 0;
	// appearence none
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	// background
	option {
		background-color: var(--body);
	}
}
.about-forex {
	padding: 24px 20px;
	@include breakpoint(xl) {
		padding: 37px 32px;
	}
	.title {
		color: #fff;
		font-family: Open Sans;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 113%; /* 16.95px */
		letter-spacing: 0.75px;
		strong {
			font-weight: 800;
		}
		margin-bottom: 23px;
	}
	.txt {
		color: #88869f;
		font-family: Open Sans;
		font-size: 12px;
		font-style: normal;
		font-weight: 400;
		line-height: 125%;
		letter-spacing: 0.6px;
		margin-bottom: 39px;
	}
	.count-items {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 10px;
		color: #88869f;
		font-family: Open Sans;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 113%;
		letter-spacing: 0.75px;
		.count {
			margin-bottom: 9px;
			color: #fff;
			font-family: Open Sans;
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: 113%;
		}
		padding-bottom: 31px;
		margin-bottom: 31px;
		border-bottom: 1px solid #88869f;
	}
	.member-rank {
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: 113%;
		letter-spacing: 0.75px;
	}
}
.text-20 {
	font-size: 20px;
}
.setting-notification-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 23px 60px;
	background: var(--section);
	border-radius: 0 0 37px 37px;
	position: sticky;
	top: 0;
	z-index: 99;
	@include breakpoint(max-md) {
		padding-inline: 20px;
	}
	@include breakpoint(max-sm) {
		img {
			width: 120px !important;
		}
		button {
			font-size: 18px;
		}
	}
}
.text-24 {
	font-size: 24px;
}
.input-right-text {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
	z-index: 9;
	color: #88869f;
}
.setting-notifications-content {
	padding-block: 44px;
	@include breakpoint(md) {
		padding-block: 94px;
	}
	.title {
		font-weight: 600;
		margin-bottom: 40px;
		@include breakpoint(md) {
			font-size: 40px;
		}
	}
	.text {
		margin-bottom: 88px;
	}
	@include breakpoint(max-md) {
		.title {
			margin-bottom: 30px;
		}
		.text {
			margin-bottom: 40px;
		}
	}
}
.setting-notification-main {
	min-height: 100dvh;
	padding-bottom: 80px;
}
.setting-notification-footer {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 18px 60px;
	background: var(--section);
	border-radius: 37px 37px 0 0;
	position: fixed;
	bottom: 0;
	z-index: 99;
	width: 100%;
	button {
		cursor: pointer !important;
		@include breakpoint(sm) {
			min-width: 185px;
		}
		&:last-child {
			background: #34343e;
		}
	}
}

.language-radio-item {
	border-radius: 6px;
	background: #2a2a37;
	padding: 3px 3px 3px 8px;
	text-align: left;
	&.style-3 {
		font-size: 16px;
		.form--radio {
			padding: 20px 16px 20px 40px;
		}
		.form-check-input {
			width: 16px;
			height: 16px;
			&:checked {
				outline: 2px solid #fff;
				border: 2px solid #000 !important;
			}
			transform: translateY(-1px);
		}
		.form--radio .form-check-label {
			font-size: 16px;
		}
		@include breakpoint(max-sm) {
			font-size: 13px;
			.form--radio {
				padding: 15px 16px 15px 30px;
			}
		}
	}
}
.course-plan-layout-header {
	gap: 8px;
	.btn {
		background: #34343e;
		border-radius: 5px;
		@include breakpoint(lg) {
			min-width: 130px;
		}
	}
	.min-w-185 {
		@include breakpoint(lg) {
			min-width: 185px;
		}
	}
	@include breakpoint(lg) {
		gap: 25px;
	}
	@include breakpoint(max-lg) {
		padding-inline: 20px;
		.btn {
			font-size: 16px;
		}
		.text-20 {
			font-size: 15px;
		}
	}
	@include breakpoint(max-sm) {
		.btn {
			font-size: 14px;
		}
	}
}
.course-plan-layout {
	padding-block: 40px;
	display: flex;
	flex-wrap: wrap;
	gap: 30px;
	aside {
		padding-top: 60px;
		.subtitle {
			font-weight: 600;
			font-size: 18px;
			line-height: 113%;
			letter-spacing: 0.05em;
			color: #ffffff;
			margin-bottom: 35px;
			&:not(:first-child) {
				margin-top: 52px;
			}
		}
		a {
			display: flex;
			align-items: center;
			font-size: 15px;
			letter-spacing: 0.05em;
			color: var(--white);
			font-weight: 700;
			gap: 13px;
			margin-bottom: 13px;
			&::before {
				width: 13px;
				height: 13px;
				border-radius: 50%;
				border: 1px solid var(--white);
				content: "";
				display: inline-block;
			}
			position: relative;
			&.active {
				&::after {
					content: "";
					position: absolute;
					height: 23px;
					width: 3px;
					background: var(--base);
					left: -10px;
				}
				&::before {
					background: var(--white);
				}
			}
		}
		width: 100%;
		max-width: 265px;
	}
	article {
		width: 0;
		flex-grow: 1;
		padding: 40px 60px;
		background: var(--section);
		border-radius: 37px 37px 0 0;
		color: #88869f;
		.title {
			font-weight: 600;
			line-height: 113%;
			letter-spacing: 1.2px;
			color: var(--white);
			margin-bottom: 24px;
		}

		.subtitle {
			font-size: 18px;
			font-weight: 600;
			margin-bottom: 12px;
		}
		@include breakpoint(md) {
			.title {
				font-size: 25px;
			}
		}
		.txt {
			font-size: 15px;
			line-height: 1.15;
		}
	}
	@include breakpoint(md) {
		.aside-menu {
			position: sticky;
			top: 100px;
		}
	}
	@include breakpoint(max-lg) {
		article {
			padding: 24px;
		}
		aside {
			max-width: 180px;
			padding-top: 30px;
			.subtitle {
				font-size: 16px;
				margin-bottom: 25px;
				&:not(:first-child) {
					margin-top: 32px;
				}
			}
			a {
				font-size: 14px;
			}
		}
	}
	@include breakpoint(max-md) {
		article {
			width: 100%;
			.subtitle {
				font-size: 15px;
			}
			font-size: 14px;
			padding-inline: 16px;
		}
		aside {
			padding-top: 0;
			padding-left: 30px;
			max-width: 100%;
		}
	}
}
.example-inputs {
	.form-select,
	.form-control {
		border: 1px solid rgba(#88869f, 0.1) !important;
		background-color: rgba(#0e0e11, 0.1);
		margin-bottom: 10px;
		&::placeholder {
			color: #ffffff;
		}
		@include breakpoint(max-sm) {
			font-size: 13px;
			padding-left: 16px;
		}
	}
	.input-right-text {
		color: #ffffff;
	}
	.form-select {
		option {
			background: var(--body);
		}
		border-radius: 5px;
		padding-left: 16px;
	}
	&.example-inputs-2 {
		.form-select,
		.form-control {
			height: 47px;
		}
	}
}
.w-sm-0 {
	@include breakpoint(sm) {
		width: 0 !important;
	}
}
.curriculam-new-section {
	background: rgba(#34343e, 0.1);
	border: 1px solid rgba(#88869f, 0.1);
	border-radius: 5px;

	.form-control {
		border-radius: 5px;
		height: 47px;
		font-size: 15px;
		padding-left: 16px;
	}
	padding: 12px 16px 13px;
	@include breakpoint(md) {
		padding: 12px 33px 13px;
	}
}
.btn-2 {
	background: rgba(#34343e, 0.1);
	cursor: pointer !important;
}
.price-wrapper {
	gap: 24px;
	column-gap: 34px;
	.item {
		width: 100%;
		max-width: 283px;
	}
	@include breakpoint(xl) {
		column-gap: 98px;
	}
}
.ql-container {
	border-top: none !important;
	border-radius: 0 0 5px 5px;
	min-height: 90px;
}
.ql-container,
.ql-toolbar {
	border-color: rgba(#88869f, 0.1) !important;
}
.ql-toolbar {
	border-radius: 5px 5px 0 0;
}
.ql-picker-label {
	color: #88869f;
}
.ql-formats .ql-stroke {
	stroke: #88869f !important;
}
.input-small-txt {
	display: block;
	margin-top: -5px;
	font-size: 10px;
}
.ql-editor.ql-blank::before {
	color: #88869f;
	font-style: normal;
}
@include breakpoint(xxl) {
	.container-xxl-fluid {
		max-width: 1450px;
	}
}
.text-text {
	.form-control-2 {
		&::placeholder {
			color: #88869f;
		}
	}
}
.example-inputs {
	.form-control-2 {
		resize: none;
		&::placeholder {
			color: #88869f;
			opacity: 1; /* Firefox */
		}
	}
}
.file-input {
	display: flex;
	gap: 20px;
	@include breakpoint(max-sm) {
		gap: 10px;
	}
}
.file-input-input {
	width: 0;
	flex-grow: 1;
	background: #131319;
	border: 1px solid rgba(#88869f, 0.1);
	border-radius: 5px;
	position: relative;
	font-size: 15px;
	overflow: hidden;
	height: 47px;
	display: flex;
	align-items: center;
	&::before {
		content: "";
		background: #131319;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 20px;
	}
	input {
		margin-left: -80px;
	}
}
.file-input-label {
	font-size: 15px;
	font-weight: 700;
	padding: 10px 35px;
	background: rgba(#34343e, 0.4);
	border: 1px solid rgba(#88869f, 0.4);
	border-radius: 5px;
	color: var(--white);
	cursor: pointer;
	@include breakpoint(max-sm) {
		font-size: 13px;
		font-weight: 500;
		padding: 13px 16px;
	}
}
.file-label-txt {
	font-size: 15px;
	@include breakpoint(max-sm) {
		font-size: 13px;
	}
}
.text-15 {
	font-size: 15px !important;
}
.upload-border-item {
	border-radius: 6px;
	border: none;
	background-color: #14141a;
	outline: none;
	box-shadow: none;
	color: var(--white);
	padding: 6px 16px;
	border: 1px solid rgba(136, 134, 159, 0.1) !important;
	background-color: rgba(14, 14, 17, 0.1);
	margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}
.bg-whiter {
	background: rgba(255, 255, 255, 0.1) !important;
}

.signalModal {

	.modal-content {
		background-color: #282834;
		padding: 25px 25px;
	}
	.text-18 {
		font-size: 18px;
		font-weight: 600;
		color: #88869f;
		padding-bottom: 10px;
	}
	.border-footer-modal {
		border: 2px solid #3b3b47;
		margin: 0 0 20px;
	}
	.cmn-btn {
		padding: 8px 35px;
	}
	.select-fields-modal {
		display: grid;
		gap: 15px;
		.form-check {
			cursor: pointer;
			display: flex;
			align-items: center;
			gap: 15px;
			margin: 0;
		}
		.form-check-input {
			cursor: pointer;
			width: 23px;
			height: 23px;
			background-color: unset;
			border: 1px solid #fff;
			border-radius: 4px;
			box-shadow: none;
			outline: none;
			&:checked[type="checkbox"] {
				background: #88869f;
			}
		}
		.form-check-label {
			cursor: pointer;
			height: 47px;
			width: 100%;
			background: #1e1e27;
			display: flex;
			align-items: center;
			font-size: 15px;
			font-weight: 600;
			color: #88869f;
			border-radius: 10px;
			padding-left: 30px;
		}
	}
	.border-bottom-2 {
		border-color: #34343e;
	}
	.usd-long {
		padding: 10px;
	}
	.signal-widget-wrapper {
		padding: 38px 16px 13px;
	}
	.signal-widget {
		margin: 0 20px;
	}
	.row.row-gap-4 {
		@media (max-width: 767.98px) {
			row-gap: 14px !important;
		}
	}
	.form-control-2 {
		background-color: var(--section-2) !important;
	}
	select.form-control-2 {
		color: #88869f !important;
	}
}
.id-input {
	width: 100%;
	font-family: "Clash Display";
	font-weight: 600;
	border: 1px solid #1e1e27;
	border-radius: 10px;
	padding: 0 25px;
	background: #282834;
	color: #fff;
	letter-spacing: 1.5px;
	box-shadow: none;
	outline: none;
}
.dolar-box {
	position: relative;
	&::before {
		background: radial-gradient(
										50% 50% at 50% 50%,
										#eed377 0%,
										rgba(240, 213, 121, 0) 100%
		);
		content: "";
		position: absolute;
		height: 70px;
		width: 70px;
		border-radius: 50%;
		opacity: 0.6;
		bottom: -15px;
		left: 50%;
		transform: translateX(-50%);
		filter: blur(10px);
		z-index: -1;
	}
}
.zm-zoom-wrapper  {
  background-color: red;
}
.zm-zoom-wrapper-content {
  height: auto;
  width: 100%;
}
.zm-zoom-wrapper-root {
  height: 60vh;
  position: relative;
  width: 100%;
  display: flex;
  touch-action: none;
}
.__select__control {
	background-color: var(--section-2) !important;
	border: none !important;
	min-height: 50px !important;
}
.__select__placeholder {
	color: var(--white) !important;
}
.__select__multi-value {
	background: var(--section-3) !important;
}
.__select__multi-value__label {
	color: var(--white) !important;
}
.__select__menu {
	background: var(--section) !important;
}
.__select__option--is-focused {
	background: rgba(#ffff, 0.051) !important;
}
.__select__option {
	font-size: 14px !important;
}
.__select__menu,
.__select__indicator,
.__select__indicator-separator {
	display: none !important;
}
.__select__input {
	color: var(--white) !important;
}
.extra-signal-widget {
	background: rgba(52, 52, 62, 0.5);
	border: 1px solid #34343e;
	backdrop-filter: blur(10px);
	border-radius: 20px;
	.signal-widget-wrapper {
		padding-top: 27px;
		margin: -1px;
		background: linear-gradient(
										211.18deg,
										rgba(52, 52, 62, 0.5) 1.99%,
										rgba(14, 14, 17, 0.5) 98.94%
		);
		border: 1px solid #34343e;
		backdrop-filter: blur(10px);
		h5 {
			font-weight: 500;
			font-size: 40px;
		}
	}
	.top-image-area {
		padding-block: 34px;
	}
	.border-bottom-2 {
		border-width: 1px;
		border-color: #34343e;
	}
	.usd-long {
		padding: 7px 16px;
	}
}
.extra-signal-widget-2 {
	background: linear-gradient(
									211.18deg,
									rgba(52, 52, 62, 0.5) 1.99%,
									rgba(14, 14, 17, 0.5) 98.94%
	);
	backdrop-filter: blur(10px);
	border: none;
	.signal-widget-wrapper {
		background: transparent;
		border: none;
		backdrop-filter: none;
	}
	.name-area {
		text-align: left;
		font-family: var(--heading-fonts);
		.name {
			font-size: 20px;
			font-weight: 600;
			margin: 0;
			color: transparent;
			-webkit-text-stroke: 1px #fff;
		}
		.subname {
			font-size: 10px;
			font-weight: 500;
		}
	}
}
.py-30 {
	padding-block: 30px;
}
.mb-19 {
	margin-bottom: 19px;
}
.w-100px {
	width: 100px;
}
.extra-signal-widget-3 {
	.signal-widget-wrapper {
		background: transparent;
		backdrop-filter: none;
		border: none;
	}
	background: #ffffff;
	--white: #212128;
	--title: #212128;
	.usd-long {
		background: rgba(#c7c7c7, 0.2);
		color: var(--title);
		.long-badge {
			background: transparent;
			color: #2e4593;
			font-size: 15px;
			font-weight: 500;
			font-family: var(--heading-fonts);
			gap: 8px;
			display: inline-flex;
			padding: 0;
			align-items: center;
			svg {
				width: 16px;
				path {
					stroke: #2e4593;
				}
			}
		}
	}
	.timestamp {
		.name {
			color: var(--title);
			font-family: var(--heading-fonts);
		}
	}
	.info-texts {
		small {
			color: #1e1e27;
		}
	}
	.btn-secondary {
		background: rgba(#88869f, 0.2);
		border: none;
		color: #88869f !important;
		font-size: 12px !important;
		font-weight: 500;
	}
}
.extra-signal-widget-4 {
	.pro-title {
		font-family: var(--heading-fonts);
		font-size: 14px;
		font-weight: 600;
		padding: 11px 28px 12px;
		border-radius: 10px;
		align-items: center;
		display: flex;
		justify-content: space-between;
		color: #fff;
		background: var(--base);
	}
	.signal-widget-wrapper {
		background: transparent;
		backdrop-filter: none;
		border: none;
		padding-top: 16px;
	}
	background: #ffffff;
	--white: #212128;
	--title: #212128;
	.usd-long {
		background: rgba(#c7c7c7, 0.2);
		color: var(--title);
		.long-badge {
			background: transparent;
			color: #2e4593;
			font-size: 15px;
			font-weight: 500;
			font-family: var(--heading-fonts);
			gap: 8px;
			display: inline-flex;
			padding: 0;
			align-items: center;
			svg {
				width: 16px;
				path {
					stroke: #2e4593;
				}
			}
		}
	}
	.timestamp {
		font-size: 10px;
		font-weight: 600;
		line-height: 1.4;
		.name {
			color: var(--title);
			font-family: var(--heading-fonts);
		}
	}
	.info-texts {
		padding-inline: 20px;
		small {
			color: #1e1e27;
		}
	}
	.btn-secondary {
		background: rgba(#88869f, 0.2);
		border: none;
		color: #88869f !important;
		font-size: 12px !important;
		font-weight: 500;
	}
	.currencies-tab {
		gap: 10px;
		button {
			font-size: 35px;
			font-weight: 700;
			color: rgba(#1e1e27, 0.5);

			font-family: var(--heading-fonts);
			&.active {
				color: #1e1e27;
			}
		}
	}
}
.my-courses-card {
	background: var(--border);
	border-radius: 20px;
	display: flex;
	color: white;
	img {
		width: 138px;
		object-fit: cover;
		border-radius: 20px;
	}
	&-content {
		padding: 15px;
		width: 0;
		flex-grow: 1;
		.title {
			font-size: 15px;
			font-weight: 700;
			letter-spacing: 0.75px;
			margin-bottom: 16px;
		}
		.name {
			font-size: 12px;
			font-weight: 700;
			font-family: "Open Sans", sans-serif;
		}
		.badge {
			color: #ffaa54;
			text-align: center;
			font-family: "Clash Display";
			font-size: 15px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			border-radius: 5px;
			background: rgba(0, 0, 0, 0.5);
			padding: 5px 15px;
		}
	}
	&:not(:last-child) {
		margin-bottom: 25px;
	}
	@media screen and (max-width: 450px) {
		img {
			width: 100px;
		}
	}
}
.welcome-modal {
	--bs-modal-width: 515px;
	--bs-modal-bg: var(--section-3);
	--bs-modal-border-radius: 24px;
	.title {
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 113%;
		letter-spacing: 0.9px;
		margin-bottom: 17px;
	}
	.text {
		color: #88869f;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 150%;
		letter-spacing: 0.5px;
		margin-bottom: 39px;
	}
	.swiper-pagination {
		bottom: unset !important;
		top: 230px;
		display: flex;
		.swiper-pagination-bullet {
			width: 0;
			flex-grow: 1;
		}
	}
	.form-check-label {
		color: #88869f;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 130%; /* 13px */
		letter-spacing: 0.5px;
	}
	.form-check {
		margin-bottom: 16px;
	}
	.form-check-input {
		border-radius: 5px;
		margin: 0;
		margin-right: 5px;
	}
	.form-check-input:not(:checked) {
		background: #88869f;
		border: none;
	}
	.swiper-pagination .swiper-pagination-bullet-active {
		background: var(--base);
	}
}
.welcome-subtitle {
	color: #fff;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 113%;
	letter-spacing: 0.6px;
	margin-bottom: 23px;
}
.welcome-img {
	background: #88869f;
	border-radius: 20px;
	height: 230px;
	margin-bottom: 40px;
	img {
		border-radius: 20px;
		width: 100%;
		max-height: 100%;
	}
}
.my-30px {
	margin-block: 30px;
}
.scroller-txt {
	height: 400px;
	overflow-y: auto;
	background: #282834;
	color: #bebdd0;
	font-family: "Open Sans";
	//border-radius: 20px;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: 130%;
	letter-spacing: 0.45px;
	margin-bottom: 16px;
	padding: 12px 38px 12px 20px;
	text-align: left;
	.txt2 {
		margin-bottom: 12px;
	}
	.txt3,
	.txt2 {
		font-weight: 400;
	}
	.txt1 {
		margin-bottom: 4px;
	}
	&::-webkit-scrollbar {
		width: 5px;
	}
	&::-webkit-scrollbar-track {
		background: transparent;
	}
	&::-webkit-scrollbar-thumb {
		background: var(--border);
		border-radius: 10px;
	}
	&::-webkit-scrollbar-thumb:hover {
		background: var(--border);
	}
}
.max-w-660 {
	@include breakpoint(lg) {
		max-width: 660px;
	}
}
.py-6px {
	padding-block: 6px;
}
.payout-trx-radio {
	.form-check-label {
		color: #88869f;
		text-align: center;
		font-family: "Open Sans";
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: 113%; /* 15.82px */
		text-align: left;
	}
	.form-check-input {
		width: 14px;
		height: 14px;
	}
	&:not(:last-child) {
		margin-bottom: 30px;
	}
}
.country-select {
	.active-btn {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 132px;
		border-radius: 10px 0px 0px 10px;
		background: #15151d !important;
		height: 46px;
		img {
			width: 39px;
		}
	}
	.form-control {
		border-radius: 0px 10px 10px 0px;
		z-index: 1;
		color: #fff;
		font-size: 15px;
		font-weight: 600;
		line-height: 113%;
		letter-spacing: 0.75px;
		border: none;
		background-color: #292933;
		color: var(--white);
		outline: none !important;
		box-shadow: none !important;
		padding-inline: 13px;
		&::placeholder {
			color: #88869f;
		}
		&:where(select) {
			color: #88869f;
		}
	}
	.dropdown-menu {
		background: #292933;
		border: none;
		a {
			color: #88869f;
			img {
				width: 39px;
			}
		}
		max-height: 220px;
		overflow-y: auto;
	}
}
input[type="number"] {
	&::-webkit-inner-spin-button,
	&::-webkit-outer-spin-button {
		display: none;
	}
}
.mb-10px {
	margin-bottom: 10px;
}
.payout-wrapper {
	@include breakpoint(xl) {
		.card-2 {
			.card-body {
				padding-inline: 32px;
			}
		}
	}
}
.visa-img {
	height: 46px;
	width: 78px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 6px;
	background: #3e3e4f;
}
.angle-down {
	position: absolute;
	right: 15px;
	top: 50%;
	transform: translateY(-50%);
}
.bg-292933 {
	background: #292933;
}
.rounded-10 {
	border-radius: 10px;
}
.date-item {
	position: relative;
	border-radius: 12px;
	border: 1px solid #88869f;
	.date-icon {
		position: absolute;
		left: 8px;
		top: 50%;
		transform: translateY(-50%);
	}
	.react-datepicker__input-container input {
		height: 46px;
		padding-left: 40px;
		padding-right: 0;
		width: 140px;
		background: transparent;
		outline: none;
		border: none;
		box-shadow: none;
		color: #88869f;
	}

	.react-datepicker {
		width: 100%;
		border-radius: 22px;
		border: none;
		background: #1b1b23;
		.react-datepicker__month-container {
			width: 100%;
		}
		.react-datepicker__header {
			color: var(--text) !important;
			background: transparent;
			border: none;
		}
		.react-datepicker__day-name,
		.react-datepicker__day,
		.react-datepicker__time-name {
			color: var(--text);
			&:hover {
				color: var(--dark);
			}
		}
		.react-datepicker__day-names {
			display: flex;
			justify-content: space-between;
			text-align: center;
			text-transform: uppercase;
			.react-datepicker__day-name {
				flex-grow: 1;
			}
		}
		.react-datepicker__week {
			display: flex;
		}
		.react-datepicker__day {
			border-radius: 2px;
			background: #282834;
			aspect-ratio: 1;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-grow: 1;
			margin: 1px;
			max-height: 60px;
			&:hover {
				color: var(--base) !important;
			}
			&.react-datepicker__day--selected {
				color: var(--white) !important;
				// background: var(--base) !important;
				border: 1px solid var(--white);
			}
		}
		.react-datepicker__day-name,
		.react-datepicker__current-month {
			color: var(--white);
		}
		// .react-datepicker__day--keyboard-selected,
		.react-datepicker__day--in-range {
			border: 1px solid var(--white);
			color: var(--body);
		}
		.react-datepicker__day--disabled {
			opacity: 0.4;
			color: var(--text) !important;
		}
		.react-datepicker__day--outside-month {
			background: #1e1e27 !important;
		}
	}
}
.rounded-12 {
	border-radius: 12px;
	border-color: #88869f;
}
.custom-thead {
	th {
		font-size: 16px;
		color: var(--white);
		font-weight: 400;
		border: none;
		padding-block: 18px;
	}
}
.table-striped {
	tbody {
		tr {
			td {
				border: none;
			}
			&:nth-child(odd) {
				td {
					background: #292933;
				}
			}
		}
	}
}
.h-28 {
	height: 28px;
}
.w-66 {
	width: 66px;
}
.__pagination {
	gap: 10px;
	flex-wrap: wrap;
	.page-item {
		background: transparent;
		a {
			background: transparent;
			border-radius: 5px;
			color: #6c7279;
			border-color: #88869f;
			font-size: 12px;
			&:hover,
			&.active {
				background: #282834;
			}
		}
	}
}
@include breakpoint(md) {
	.text-md-24 {
		font-size: 24px;
	}
	.text-md-20 {
		font-size: 20px;
	}
}
.word-nobreak {
	white-space: nowrap;
}

.react-datepicker-popper[data-placement^="bottom"]
.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
.react-datepicker__triangle::after {
	border-bottom-color: #282834;
}

.react-datepicker-popper[data-placement^="top"]
.react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
.react-datepicker__triangle::after {
	border-top-color: #282834;
}
.reels-modal {
	--bs-modal-width: 1100px;
	--bs-modal-bg: transparent;
	z-index: 9999;
}
.reels-slider-wrapper {
	border-radius: 32px;
	background: linear-gradient(0deg, #363636 0%, #363636 100%), #d9d9d9;
	padding: 16px;
	backdrop-filter: blur(12.5px);
	gap: 5px;
	max-width: 483px;
	margin: 0 auto;
	position: relative;
	.next-btn,
	.prev-btn {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 999;
	}
	.prev-btn {
		right: calc(100% + 8px);
	}
	.next-btn {
		left: calc(100% + 8px);
	}
	.swiper {
		overflow: visible;
	}
	@media screen and (min-width: 425px) {
		padding: 20px;
	}
}
@keyframes zoomIn {
	0% {
		transform: scale(0.5);
	}
	100% {
		transform: scale(1);
	}
}
.swiper-slide-active {
	.reels-video-item {
		animation: zoomIn 0.6s;
		-webkit-animation: zoomIn 0.6s;
		-moz-animation: zoomIn 0.6s;
	}
}
.reels-video-item {
	aspect-ratio: 461 / 688;
	width: 100%;
	max-height: 80vh;
	border-radius: 22px;
	position: relative;
	// animation: zoomIn 0.6s;
	// -webkit-animation: zoomIn 0.6s;
	// -moz-animation: zoomIn 0.6s;
	video {
		aspect-ratio: 461/688;
		width: 100%;
		object-fit: cover;
		object-position: center center;
		border-radius: 22px;
		overflow: hidden;
		max-height: 100%;
	}
	.video > div {
		height: unset !important;
	}
	.video:not(:hover) {
		.pos-center {
			opacity: 0;
		}
	}
	* {
		max-width: 100%;
	}
	font-size: 14px;
	.name {
		font-size: 18px;
		font-weight: 600;
		letter-spacing: 0.9px;
		font-family: var(--heading-fonts);
		// line limit 2
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-overflow: ellipsis;
		color: var(--white);
		line-height: 1.13;
		margin-bottom: 12px;
	}
	.video-bottom-content {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		padding: 20px;
	}
	.reel-customer-info {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		padding: 15px;
		z-index: 99;
		display: flex;
		justify-content: space-between;
		.dropdown-menu {
			a {
				color: #88869f;
			}
		}
		.customer {
			display: flex;
			gap: 10px;
			text-align: left;
			align-items: center;
			img {
				border-radius: 28px;
				border: 1px dashed #e2295b;
				width: 28px;
				height: 28px;
			}
			.c-name {
				font-size: 14px;
				font-weight: 600;
				line-height: 113%;
				letter-spacing: 0.7px;
				margin: 0;
				margin-bottom: 3px;
			}
			.c-info {
				color: #bebebe;
				font-family: "Clash Display";
				font-size: 12px;
				font-style: normal;
				font-weight: 400;
				line-height: 113%;
				letter-spacing: 0.6px;
			}
		}
	}
}
.pos-center {
	color: var(--white);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 9;
}
.reply-form {
	margin-bottom: 9px;
	.form-control {
		height: 39px;
		background: transparent;
		border: 1px solid rgba(#ffffff, 0.7);
		padding-right: 75px;
		font-size: 12px;
		padding-left: 13px;
	}
	.btn-group {
		display: flex;
		align-items: center;
		position: absolute;
		right: 10px;
		top: 0;
		button {
			height: 39px;
			width: 30px;
		}
	}
}
.customer-center-content {
	.title {
		font-size: 18px;
		font-weight: 600;
		letter-spacing: 0.9px;
		font-family: var(--heading-fonts);
		color: var(--white);
		margin-bottom: 20px;
	}
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	transform: translate(-50%, -50%);
	img {
		width: 100px;
		aspect-ratio: 1;
		border-radius: 50%;
		object-fit: cover;
	}
	.customer-name {
		font-size: 34px;
		margin-top: 20px;
	}
}

